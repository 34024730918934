//Please don't use google translate for arabic text

export const text={
    virtual_humans: {
        "en": "Virtual Humans",
        "ar": "البشر الظاهريون"
      },
    welcome_to:{
        "en": "Welcome to",
        "ar": "مرحبا بك في"
    },
    proceed:{
        "en": "Proceed",
        "ar": "يتابع"
    },
    sign_in_as_admin:{
        "en": "Sign in as Admin",
        "ar": "قم بتسجيل الدخول كمسؤول"
    },
    sign_in:{
        "en": "Sign In"
    },
    login:{
        "en": "Login"
    },
    sign_in_successful:{
        "en": "Sign In Successful"
    },
    mobile_number:{
        "en": "Mobile Number"
    },
    email_id:{
        "en": "Email Id"
    },
    select:{
        "en": "Select"
    },
    select_role:{
        "en": "Select role"
    },
    enter_mobile_number:{
        "en": "Enter mobile number"
    },
    enter_email_id:{
        "en": "Enter email id"
    },
    otp:{
        "en": "OTP"
    },
    as_sent_in_email:{
        "en": "as sent in your email"
    },
    get_otp:{
        "en": "Get OTP"
    },
    mobile_number_otp:{
        "en": "Mobile Number OTP"
    },
    email_otp:{
        "en": "Email OTP"
    },
    enter_otp:{
        "en": "Enter OTP"
    },
    resend_otp:{
        "en": "Resend OTP"
    },
    in:{
        "en": "in"
    },
    regenerate_otp:{
        "en": "Regenerate OTP"
    },
    simulation:{
        "en": "Simulation"
    },
    dashboard_option:{
        "en": "Dashboard"
    },
    role:{
        "en": "Role"
    },
    assign_role:{
        "en": "Assign Role"
    },
    validations:{
        invalid_mobile_number:{
            "en": "Invalid mobile number"
        },
        invalid_email_address:{
            "en": "Invalid email address"
        },
        number_not_found:{
            "en": "We didn’t find the number in our database, please try again"
        },
        account_not_found:{
            "en": "We didn’t find the email id in our database, please try again"
        },
        number_is_disabled:{
            "en": "The number you are trying to login is disabled"
        },
        account_is_disabled:{
            "en": "The account you are trying to login is disabled"
        },
        something_is_wrong:{
            "en": "Something is not right. Try again."
        },
        participant_doesnt_exist:{
            "en": "Participant doesn't exist"
        },
        invalid_number_format:{
            "en": "Invalid phone number format"
        },
        invalid_input:{
            "en": "Invalid input"
        },
        phone_number_required:{
            "en": "Phone number is required"
        },
        invalid_otp:{
            "en": "Invalid OTP"
        },
        failed_to_add_participant:{
            "en": "Failed to add participant."
        },
        number_already_exists:{
            "en": "Mobile number already exists"
        },
        something_went_wrong_refresh:{
            "en": "Something went wrong, Please refresh the page"
        },
        age_is_required:{
            "en": "Age is required"
        },
        age_limit_is_18_60:{
            "en": "Age limit is 18-60 years"
        },
        age_must_be_integer:{
            "en": "Age must be an integer"
        },
        age_must_be_positive:{
            "en": "Age must be positive"
        },
        gender_is_required:{
            "en": "Gender is required"
        },
        invalid_gender:{
            "en": "Invalid Gender"
        }
    },
    participant_consent_text:{
        "en": "I understand that my data is anonymised and that data privacy is maintained through RDP."
    },
    rdp_consent_text:{
        "en": "I agree that Participant has shared their consent offline to provide the data which is anonymised on the platform"
    },
    please_enter_otp_mobile_email:{
        "en": "Please enter the OTP sent to your registered Email Id and Mobile number"
    },
    welcome:{
        "en": "Welcome"
    },
    logout:{
        "en": "Logout"
    },
    add_participant:{
        "en": "Add Participant"
    },
    create_user:{
        "en": "Create User"
    },
    search:{
        "en": "Search"
    },
    filter_by:{
        "en": "Filter by"
    },
    filter:{
        "en": "Filter"
    },
    logout_confirm:{
        "en": "Please confirm if you want to logout"
    },  
    cancel:{
        "en": "Cancel"
    },
    confirm:{
        "en": "Confirm"
    },
    first_name:{
        "en": "First Name"
    },
    close:{
        "en": "Close"
    },
    add:{
        "en": "Add"
    },
    add_another:{
        "en": "Add Another"
    },
    create:{
        "en": "Create"
    },
    create_another:{
        "en": "Create Another"
    },
    enter_only_first_name:{
        "en": "Enter only first name"
    },
    search_by_uid_name:{
        "en": "Search by User ID or Name..."
    },
    search_by_id_name:{
        "en": "Search by ID or Name..."
    },
    age:{
        "en": "Age"
    },
    enter_age:{
        "en": "Enter Age"
    },
    from:{
        "en": "From"
    },
    to:{
        "en": "To"
    },
    filter_errors:{
        from_shouldnt_exceed_to:{
            "en": "'From' age should not exceed the 'To' age"
        },
        field_cannot_be_empty:{
            "en": "This field cannot be empty"
        },
        age_between_18_to_60:{
            "en": "Age should be between 18 and 60"
        }
    },
    gender:{
        "en": "Gender"
    },
    male:{
        "en": "Male"
    },
    female:{
        "en": "Female"
    },
    other:{
        "en": "Non-Binary"
    },
    non_binary:{
        "en": "Non-Binary"
    },
    genders:{
        "male":{
            "en": "Male"
        },
        "female":{
            "en": "Female"
        },
        "other":{
            "en": "Non-Binary"
        },
        "Non Binary":{
            "en": "Non-Binary"
        },
        "nonBinary": {
            "en": "Non-Binary"
        }
    },
    status_text:{
        "en": "Status"
    },
    active:{
        "en": "Active"
    },
    inactive:{
        "en": "Inactive"
    },
    apply:{
        "en": "Apply"
    },
    user_creation_errors:{
        username_is_required:{
            "en": "Username is required."
        },
        firstname_is_required:{
            "en": "First name is required."
        },
        firstname_character_length:{
            "en": "First name must be more than one character."
        },
        please_enter_valid_number:{
            "en": "Please enter a valid mobile number."
        },
        username_character_length:{
            "en": "Username must be more than one character long."
        },
        please_select_a_role:{
            "en": "Please select a role."
        },
    },
    participant_add_success:{
        "en": "Participant added successfully"
    },
    change_status:{
        "en": "Change Status"
    },
    
    roles: {
        "Raw Data Provider": {
          "en" : "Raw Data Provider",
        },
        "Admin": {
          "en": "Admin",
        },
        "Urban Planner": {
            "en": "Urban Planner"
        }
    },
    status: {
        "Invitation Sent": {
          "en" : "Invitation Sent",
        },
        "Mobile Number Verified": {
          "en": "Mobile Number Verified",
        },
        "Profile Completed": {
          "en": "Profile Completed",
        },
        "Diagnostics Completed": {
          "en": "Diagnostics Completed",
        },
        "Experience Completed": {
          "en": "Experience Completed",
        },
    },
    dashboard:{
        page:{
            "en": "Page"
        },
        participant_id:{
            "en": "Participant Id"
        },
        user_id:{
            "en": "User Id"
        },
        profile_status:{
            "en": "Profile Status"
        },
        actions:{
            "en": "Actions"
        },
        no_results_found:{
            "en": "No Results Found"
        },
        we_coundnt_find:{
            "en": "We couldn’t find what you are looking for."
        },
        please_try_again:{
            "en": "Please try again"
        },
        profile:{
            "en": "Profile"
        },
        vr:{
            "en": "VR"
        },
        participant_deactivate_confirmation:{
            "en": "Please confirm if you want to deactivate participant"
        },
        user_deactivate_confirmation:{
            "en": "Please confirm if you want to deactivate user"
        }
    },
    participant_details:{
        "en": "Participant Details"
    },
    user_details:{
        "en": "User Details"
    },
    hey:{
        "en": "Hey"
    },
    welcome_to_the_VH:{
        "en": "Welcome to the Virtual Humans"
    },
    current_status:{
        "en": "Current Status"
    },
    profile_information:{
        "en": "Profile Information"
    },
    numbers:{
        "1":{
            "en": "1"
        },
        "2":{
            "en": "2"
        },
        "3":{
            "en": "3"
        },
        "4":{
            "en": "4"
        }
    },
    home:{
        "en": "Home"
    },
    settings:{
        "en": "Settings"
    },
    update_profile:{
        "en": "Update Profile"
    },
    save:{
        "en": "Save"
    },
    toast:{
        profile_updated_successfully:{
            "en": "Profile Updated Successfully"
        },
        failed_to_update_profile:{
            "en": "Failed to update profile"
        }
    },
    diagnostics:{
        "en": "Diagnostics"
    },
    plesae_continue_on_vr:{
        "en": "Please continue on VR by signing-in using the same mobile number"
    },
    to_ensure_privacy_mobile_number_not_displayed:{
        "en": "To ensure data privacy, your mobile number is not displayed"
    },
    user_guide:{
        "en": "User Guide"
    },
    all_info_accessed_through_home:{
        "en": "All the current status information is accessed through home."
    },
    session_timeout:{
        "en": "Session Timeout"
    },
    you_are_logged_outof_system:{
        "en": "You are logged out of system, please login again to continue"
    },
    refresh:{
        "en": "Refresh"
    },
    simulations: {
        age_groups:{
            "en": "Age Groups"
        },
        select_age_group:{
            "en": "Select age group"
        },
        virtual_world:{
            "en": "Virtual World"
        },
        configure_simulation:{
            "en": "Configure Simulation"
        },
        configure_simulation_recording:{
            "en": "Configure Simulation Recording"
        },
        visualization_dashboard:{
            "en": "Visualization Dashboard"
        },
        number_of_npcs:{
            "en": "Number of NPCs"
        },
        select_date:{
            "en": "Select Date"
        },
        select_time:{
            "en": "Select Time"
        }, 
        area:{
            "en": "Area"
        },
        threed_world:{
            "en": "3D World"
        },
        submit:{
            "en": "Submit"
        },
        go_to_visualisation_dashboard:{
            "en": "Go to Visualisation Dashboard"
        },
        your_video_will_be_available_soon:{
            "en": "Your video will be available soon"
        },
        simulation_months:{
            "en": "Simulation Month/s"
        },
        parameters:{
            "en": "Parameters"
        },
        light:{
            "en": "Light"
        },
        sound:{
            "en": "Sound"
        },
        temperature:{
            "en": "Temperature"
        },
        wind:{
            "en": "Wind"
        },
        simulations_heading:{
            "en": "Simulations"
        },
        area_list:{
            "en": "Area List"
        },
        areas:{
            "en": "Areas"
        },
        materials:{
            "en": "Materials"
        },
        surroundings:{
            "en": "Surroundings"
        },
        top:{
            "en": "Top"
        },
        floor:{
            "en": "Floor"
        }
    }
}
