export const evolutionGraph1 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/bfde9709-cd5e-4c69-b724-8f605651c1bd/sheets/bfde9709-cd5e-4c69-b724-8f605651c1bd_247fbd73-7045-461d-a422-389fccb49ca3/visuals/bfde9709-cd5e-4c69-b724-8f605651c1bd_11145494-fda6-4549-9f36-b84b5a20c3ac?directory_alias=neom-aws-quicksight";
export const evolutionGraph2 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/bfde9709-cd5e-4c69-b724-8f605651c1bd/sheets/bfde9709-cd5e-4c69-b724-8f605651c1bd_247fbd73-7045-461d-a422-389fccb49ca3/visuals/bfde9709-cd5e-4c69-b724-8f605651c1bd_87ad34f3-12ae-45ab-9041-608e9b912891?directory_alias=neom-aws-quicksight";
export const evolutionGraph3 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/bfde9709-cd5e-4c69-b724-8f605651c1bd/sheets/bfde9709-cd5e-4c69-b724-8f605651c1bd_247fbd73-7045-461d-a422-389fccb49ca3/visuals/bfde9709-cd5e-4c69-b724-8f605651c1bd_7ef88d67-936b-4783-b4bc-f8fcab025096?directory_alias=neom-aws-quicksight";
export const evolutionGraph4 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/bfde9709-cd5e-4c69-b724-8f605651c1bd/sheets/bfde9709-cd5e-4c69-b724-8f605651c1bd_247fbd73-7045-461d-a422-389fccb49ca3/visuals/bfde9709-cd5e-4c69-b724-8f605651c1bd_fe32609d-1e06-4aa9-bedf-2d4a1e3d5ead?directory_alias=neom-aws-quicksight";

export const engagementGraph1 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_d49a94f8-c2e2-4f4a-ac33-efa7b7773a24?directory_alias=neom-aws-quicksight";
export const engagementGraph2 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_1c162e82-6d35-4b90-963b-b36ce8645fb8?directory_alias=neom-aws-quicksight";
export const engagementGraph3 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_a4b27234-1f21-40e0-a1da-feeddc1bc8f7?directory_alias=neom-aws-quicksight";
export const engagementGraph4 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_1025ebe4-b3da-421c-bccf-ab45bea4e06f?directory_alias=neom-aws-quicksight";
export const engagementGraph5 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_7b68aae0-312d-4f65-8f41-24d704fed32d?directory_alias=neom-aws-quicksight";
export const engagementGraph6 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_8fc9ef18-ad3d-4b8e-b2c7-92752bf6c082?directory_alias=neom-aws-quicksight";
export const engagementGraph7 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_391a6985-eead-403a-8afb-de092b260a28?directory_alias=neom-aws-quicksight";
export const engagementGraph8 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_8ccfaa5c-a754-45f4-bcc4-12bc2b7fa90b?directory_alias=neom-aws-quicksight";
export const engagementGraph9 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_8d90a33d-2b5d-43e3-b1fd-f086cdcc194c?directory_alias=neom-aws-quicksight";
export const engagementGraph10 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_ab0d1e27-4383-43b9-ac25-153a51b03462?directory_alias=neom-aws-quicksight";
export const engagementGraph11 =
  "https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/637423488121/dashboards/d7fc2418-450f-47ea-9a18-97380f6ae6a8/sheets/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3d6eec49-b4a3-4642-b06e-badbf9407692/visuals/d7fc2418-450f-47ea-9a18-97380f6ae6a8_3714a514-b102-463c-95c7-6425d4a30538?directory_alias=neom-aws-quicksight";

export const categoryOptions = [
  { value: "Evolution", label: "Evolution" },
  { value: "Engagement", label: "Engagement" },
];

export const module45Data = [
  { name: "Module 45 Reports", date: "10 Sep, 24", status: "Done" },
];
export const tiranData = [
  { name: "Tiran Reports", date: "10 Sep, 24", status: "Done" },
];

export const graphs = {
  evolution: [
    { url: evolutionGraph1, filtered: false, modules: ["Module45", "Tiran"] },
    { url: evolutionGraph4, filtered: false, modules: ["Module45", "Tiran"] },
    { url: evolutionGraph2, filtered: false, modules: ["Module45", "Tiran"] },
    { url: evolutionGraph3, filtered: true, modules: ["Module45", "Tiran"] },
  ],
  engagement: [
    { url: engagementGraph1, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph2, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph3, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph4, filtered: false, modules: ["Module45", "Tiran"] },
    { url: engagementGraph5, filtered: true, modules: ["Module45"] },
    { url: engagementGraph6, filtered: false, modules: ["Module45"] },
    { url: engagementGraph7, filtered: true, modules: ["Tiran"] },
    { url: engagementGraph8, filtered: false, modules: ["Tiran"] },
    { url: engagementGraph9, filtered: true, modules: ["Module45", "Tiran"] },
    { url: engagementGraph10, filtered: true, modules: ["Module45", "Tiran"] },
    { url: engagementGraph11, filtered: true, modules: ["Module45", "Tiran"] },
  ],
};

export const m45PersonalSkillsOptions = [
  { value: "Area1", label: "Walkable Street" },
  { value: "Area3", label: "Stadium" },
  { value: "Area4", label: "Hospital" },
  { value: "Area5", label: "Park" },
  { value: "Area6", label: "NEOMU" },
  { value: "Area7", label: "Library" },
];
export const tiranPersonalSkillsOptions = [
  { value: "Area1", label: "Waterfront" },
  { value: "Area5", label: "Miracle Green" },
  { value: "Area3", label: "Academia" },
  { value: "Area4", label: "Wonderdome" },
];
export const allField = { value: "All", label: "All" };
