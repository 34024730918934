import React, { useState } from "react";
import styles from "./AdminNavigation.module.css";
import AdminLogoutModal from "../../../utils/Modals/AdminModal/AdminLogoutModal/AdminLogoutModal";
import CreateUserModal from "../../../utils/Modals/AdminModal/CreateUserModal/CreateUserModal";
import useCreateUserModal from "../../../utils/customHook/useCreateUserModal";
import SearchModal from "../../../utils/Modals/AdminModal/SearchModal/SearchModal";
import AdminFilterModal from "../../../utils/Modals/AdminModal/FilterModal/AdminFilterModal";
import SearchIcon from "../../../assets/svgicons/SearchIcon";
import FilterIcon from "../../../assets/svgicons/FilterIcon";
import LogoutIcon from "../../../assets/svgicons/LogoutIcon";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";

const AdminNavigation = ({
  activeIcon,
  handleLogoutClick,
  handleModalConfirm: handleLogoutModalConfirm,
  toggleMenu = () => {},
}) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    isModalOpen,
    handleCreateUserClick,
    handleModalConfirm,
    handleModalCancel,
    responseMessage,
    isResponseSuccess,
    setResponseMessage,
  } = useCreateUserModal();

  const adminInfo = JSON.parse(localStorage.getItem("adminInfo"));

  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const hideLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const confirmLogout = () => {
    handleLogoutClick(); // Assuming this is passed down to handle the logout action
    hideLogoutModal(); // Close the modal
    handleLogoutModalConfirm();
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowFilterModal(true);
  };

  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleSearchClick = () => {
    setShowSearchModal(true); // Show the search modal
  };

  const closeSearchModal = () => {
    setShowSearchModal(false); // Close the search modal
  };

  // Check if the username is 'Urbanplanner'
  const isUrbanPlanner = adminInfo?.role === "Urban Planner";

  return (
    <div className={styles.statusSection}>
      {/* Only show if the user is NOT Urbanplanner */}
      {!isUrbanPlanner && (
        <div>
          {/* Button to open the create user modal */}
          <button
            className={styles.createUserButton}
            onClick={() => {
              toggleMenu();
              handleCreateUserClick();
            }}
          >
            {text?.create_user[lang]}
          </button>

          {/* Search and Filter icons section */}
          <div
            className={styles.iconWithLabel}
            onClick={() => {
              toggleMenu();
              handleSearchClick();
            }}
          >
            <SearchIcon styles={styles} />
            <span
              className={
                activeIcon === "search"
                  ? styles.activeIconLabel
                  : styles.iconLabel
              }
            >
              {text?.search[lang]}
            </span>
          </div>

          <SearchModal isOpen={showSearchModal} onClose={closeSearchModal} />

          <div
            className={styles.iconWithLabel}
            onClick={(event) => {
              toggleMenu();
              handleFilterClick(event);
            }}
          >
            <FilterIcon styles={styles} />
            <span
              className={
                activeIcon === "filter"
                  ? styles.activeIconLabel
                  : styles.iconLabel
              }
            >
              {text?.filter_by[lang]}
            </span>
          </div>
          <AdminFilterModal
            isOpen={showFilterModal}
            onClose={closeFilterModal}
            anchorEl={anchorEl}
          />
        </div>
      )}

      {/* Logout section - Always shown */}
      <div
        className={`${styles.iconWithLabel} ${styles.logOutIcon}`}
        onClick={() => {
          toggleMenu();
          showLogoutModal();
        }}
      >
        <LogoutIcon styles={styles} />
        <span className={styles.iconLabel}>{text?.logout[lang]}</span>
      </div>

      {/* Logout modal */}
      <AdminLogoutModal
        isOpen={isLogoutModalOpen}
        onCancel={hideLogoutModal}
        onConfirm={confirmLogout}
      />

      {/* Create user modal using the state and handlers from the custom hook */}
      <CreateUserModal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        responseMessage={responseMessage}
        isResponseSuccess={isResponseSuccess}
        setResponseMessage={setResponseMessage}
      />
    </div>
  );
};

export default AdminNavigation;
