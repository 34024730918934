import React, { createContext, useState } from 'react';

export const SimulationContext = createContext();

export const SimulationProvider = ({ children }) => {
  const initialSimulationState = {
    apiData: {},
    simulationType: {
      modelType: null,
      parameters: [],
      areas: [],
      materialSurroundings: [],
      materialTop: [],
      materialFloor: [],
    },
    ageGroupSelections: {
      male: null,
      female: null,
      nonBinary: null,
    },
    simulationDuration: {
      durationDays: null,
      durationTime: null,
      startDate: null,
      endDate: null,
    },
    totalNPCs: null,
    simulationRecording: {
      startDate: null,
      startTime: null,
    }
  };

  const [simulationState, setSimulationState] = useState(initialSimulationState);

  const updateSimulationState = (newData) => {
    setSimulationState((prevState) => ({ ...prevState, ...newData }));
  };

  const resetSimulationState = () => {
    setSimulationState(initialSimulationState);
  };

  return (
    <SimulationContext.Provider
      value={{ simulationState, updateSimulationState, resetSimulationState }}
    >
      {children}
    </SimulationContext.Provider>
  );
};
