const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const verifyParticipant = BASE_URL + "auth-participant/verify";
export const loginParticipant = BASE_URL + "auth-participant/login";
export const updateProfile = BASE_URL + "participants/submitprofile";
// export const updateProfilesSubmit = BASE_URL + "participants/test";
export const signInParticipant = BASE_URL + "auth-participant/login/response";
export const participantProfileView = BASE_URL + "participants/profile";
export const logoutProfile = BASE_URL + "auth-participant/logout";
export const refreshToken = BASE_URL + "auth-participant/refreshtoken";
export const getParticipantStatus = BASE_URL + "participants/participantstatus";
export const documentLink = "https://neom-virtual-human-assets.s3.ap-south-1.amazonaws.com/User_Guide_VR.pdf";