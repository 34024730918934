import React, { useCallback, useState } from "react";
import styles from "./RDPUserTable.module.css";
import axios from "axios";
import * as _ from "lodash";
import constants from "../../../constants";
import CreateParticipantModal from "../../../utils/Modals/ParticipantModal/CreateParticipantModal/CreateParticipantModal";
import RDPParticipantsDetailsTable from "../RDPParticipantsDetailsTable/RDPParticipantsDetailsTable";
import { useRDP } from "../../../context/rdpContext";
import { rdpCreateParticipant } from "../../apiRoutes/rdpApi/rdp-api";
import { Box, Checkbox, Popover, TextField } from "@mui/material";
import "./RDPUserTable.scss";
import { useImageAssets } from "../../../context/ImageAssetsContext";
import magnifyGlass from "../../../assets/svgicons/magnifyGlass.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CropSquareTwoToneIcon from "@mui/icons-material/CropSquareTwoTone";
import { useAuth } from "../../../context/AuthContext";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";
import Banner from "../../../sharedComponents/Banner/Banner";

const RDPUserTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState("");
  const { selectedFilters, setSelectedFilters } = useRDP();
  const [stagedFilters, setStagedFilters] = useState({ ...selectedFilters });
  const [responseMessage, setResponseMessage] = useState("");
  const [isResponseSuccess, setIsResponseSuccess] = useState(true);
  const [ageError, setAgeError] = useState({ fromAge: false, toAge: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterText, setFilterText] = useState("");
  const { imageAssetsData, loading } = useImageAssets();
  const { authData, signOut } = useAuth();
  const [networkError, setNetworkError] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (clickOutside) => {
    setAnchorEl(null);
    setAgeError({ fromAge: false, toAge: false });
    if (clickOutside) setStagedFilters({ ...selectedFilters });
  };

  const open = Boolean(anchorEl);

  const handleCreateParticipantClick = () => {
    setIsModalOpen(true);
  };

  const handleModalConfirm = async (username, fullPhoneNumber) => {
    const idToken =
      authData?.data?.tokenresponse?.IdToken ||
      localStorage.getItem("id_token");
    const accessToken =
      authData?.data?.tokenresponse?.AccessToken ||
      localStorage.getItem("access_token");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        id_token: idToken,
      },
      timeout: 10000,
    };
    const requestBody = {
      name: username,
    };
    if (fullPhoneNumber) {
      requestBody["phone_number"] = fullPhoneNumber;
    }

    try {
      const response = await axios.post(
        rdpCreateParticipant,
        requestBody,
        config
      );
      console.log("Participant created successfully:", response.data);
      setIsUserCreated(response.data); 
      setResponseMessage(text?.participant_add_success[lang]); // Set success message
      setIsResponseSuccess(true);
    } catch (error) {
      console.error(error.message);
      let errorMessage = text?.validations?.failed_to_add_participant[lang]; // Default error message
      if (error.response) {
        console.error(error);
        if (
          error.response?.data?.message ===
            constants.USER_ACCOUNT_ALREADY_EXISTS ||
          error.response?.data?.message ===
            constants.USER_ACCOUNT_ALREADY_EXISTS_AND_EXPIRED
        ) {
          errorMessage = text?.validations.number_already_exists[lang];
        } else if (
          error.response.data.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else {
          errorMessage = `${
            error.response.data.Error || error.response.data.message
          }`;
        }
        setResponseMessage(errorMessage); // Set error message
        setIsResponseSuccess(false);
      } else if (error.message.includes("Network Error")) {
        setNetworkError(true)
        setIsResponseSuccess(false);
      } else if (error.code === 'ECONNABORTED') {
        // Timeout error
        console.error('Request timed out:', error.message);
        setNetworkError(true)
        setIsResponseSuccess(false);
      } 
    }
  };

  const handleAgeChange = (field, value) => {
    // Handle the case where the input is cleared
    if (value === "") {
      setAgeError((prev) => ({ ...prev, [field]: false }));
      setStagedFilters((prev) => ({ ...prev, [field]: value }));
  
      // Check the other field to see if it has a value
      const otherField = field === "fromAge" ? "toAge" : "fromAge";
      const otherFieldValue = stagedFilters[otherField];
  
      if (otherFieldValue === "" || otherFieldValue === null) {
        setAgeError((prev) => ({ ...prev, emptyFieldError: false }));
      } else {
        setAgeError((prev) => ({ ...prev, emptyFieldError: true }));
      }
  
      return;
    }

    // Check if the input is one or two digits. If not, simply ignore the input.
    if (!/^\d{1,2}$/.test(value)) {
      return;
    }

    const numericValue = parseInt(value, 10);
    const fromAge =
      field === "fromAge"
        ? numericValue
        : parseInt(stagedFilters.fromAge || 0, 10);
    const toAge =
      field === "toAge" ? numericValue : parseInt(stagedFilters.toAge || 0, 10);

    if (numericValue < 18 || numericValue > 60) {
      setAgeError((prev) => ({
        ...prev,
        [field]: true,
        rangeError: false,
        emptyFieldError: false,
      }));
      setStagedFilters((prev) => ({ ...prev, [field]: value }));
    } else {
      let newErrors = { ...ageError, [field]: false };

      if (
        (field === "fromAge" &&
          value !== "" &&
          (stagedFilters.toAge === "" || stagedFilters.toAge === null)) ||
        (field === "toAge" &&
          value !== "" &&
          (stagedFilters.fromAge === "" || stagedFilters.fromAge === null))
      ) {
        newErrors.emptyFieldError = true;
        newErrors.rangeError = false;
      } else if (
        (field === "fromAge" && numericValue > toAge && !isNaN(toAge)) ||
        (field === "toAge" && numericValue < fromAge && !isNaN(fromAge))
      ) {
        newErrors.rangeError = true;
        newErrors.emptyFieldError = false;
      } else {
        newErrors.rangeError = false;
        newErrors.emptyFieldError = false;
      }

      setAgeError(newErrors);
      setStagedFilters((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleCheckboxChange = (filterValue) => {
    const isSelected = stagedFilters.gender.includes(filterValue);
    let updatedGender;

    if (isSelected) {
      updatedGender = stagedFilters.gender.filter((f) => f !== filterValue);
    } else {
      updatedGender = [...stagedFilters.gender, filterValue];
    }

    setStagedFilters({ ...stagedFilters, gender: updatedGender }); // Update the temporary staged filters state
  };

  const handleStatusCheckboxChange = (statusValue) => {
    const isSelected = stagedFilters.statuses.includes(statusValue);
    let updatedStatuses;

    if (isSelected) {
      updatedStatuses = stagedFilters.statuses.filter((s) => s !== statusValue);
    } else {
      updatedStatuses = [...stagedFilters.statuses, statusValue];
    }

    setStagedFilters({ ...stagedFilters, statuses: updatedStatuses }); // Update the staged filters state
  };

  const handleApplyFilters = () => {
    setSelectedFilters(stagedFilters); // Apply the staged filters
    console.log(selectedFilters);
    handleClose(false);
    console.log(stagedFilters);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const debounceFuncUserFilter = useCallback(
    _.debounce((value) => {
      const obj = { ...stagedFilters, inputValue: value };
      setStagedFilters(obj);
      setSelectedFilters(obj);
    }, 500),
    [stagedFilters]
  );

  const handleFilterText = (value) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (value === "") {
      setFilterText(value);
      debounceFuncUserFilter(value);
    }
    // If the input is not empty, check the regex and length constraints
    else if (value && regex.test(value) && value.length <= 15) {
      setFilterText(value);
      debounceFuncUserFilter(value);
    }
  };

  const handleKeyPress = (event) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
    if (
      !allowedKeys.includes(event.key) &&
      (event.key < "0" || event.key > "9")
    ) {
      event.preventDefault();
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className={styles.userDetailsContainer}>
      {networkError && <Banner message={text?.validations?.something_went_wrong_refresh[lang]} onRefresh={handleRefresh} action={text?.refresh[lang]}/>}
      <div className={styles.headerSection}>
        <h1 className={styles.title}>{text?.participant_details[lang]}</h1>
        <div className={styles.controls}>
          <div className={styles.searchInputContainer}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={text?.search_by_id_name[lang]}
              onChange={(e) => handleFilterText(e.target.value)}
              value={filterText}
            />
            {filterText && filterText?.length > 0 ? (
              <CloseRoundedIcon
                className={styles.clearIcon}
                fontSize="small"
                onClick={() => handleFilterText("")}
              />
            ) : (
              <img
                src={magnifyGlass}
                className={styles.magnifyGlassIcon}
                alt="X"
                draggable="false"
              />
            )}
          </div>
          <div className={styles.filterSection} onClick={handleClick}>
            <div className={styles.iconTextWrapper}>
              <FilterListRoundedIcon
                fontSize="small"
                className={styles.filterIcon}
              />
              <span className={styles.filterText}>{text?.filter[lang]}</span>
            </div>
          </div>

          <Popover
            id={"filter-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={() => handleClose(true)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: "12px",
                  mt: 1,
                  width: "294px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom box-shadow
                },
              },
            }}
          >
            <div className={styles.filterContentWrapper}>
              <div className={styles.filterHeader}>{text?.filter_by[lang]} :</div>
              <div className={styles.filterCategory}>
                <div className={styles.filterTitle}>{text?.age[lang]}</div>
                <Box>
                  <Box className={styles.ageBox}>
                    <Box className="flex-align-center" sx={{ gap: 1 }}>
                      <label className={styles.fromLabel} htmlFor="From">
                      {text?.from[lang]}
                      </label>
                      <TextField
                        id="age-from"
                        label=""
                        variant="outlined"
                        type="number"
                        value={stagedFilters.fromAge}
                        onChange={(event) => {
                          let value = event.target.value;
                          if (value === "") {
                            handleAgeChange("fromAge", ""); // Allow clearing the input
                          } else {
                            value = Number(value);
                            if (value > 0) {
                              handleAgeChange("fromAge", value);
                            } else if (value === 0) {
                              handleAgeChange("fromAge", ""); // Start from 1 instead of 0
                            } else {
                              handleAgeChange("fromAge", "");
                            }
                          }
                        }}
                        size="small"
                        InputProps={{
                          sx: {
                            height: "30px",
                            width: "48px",
                            fontSize: "14px",
                            fontFamily: "Brown, sans-serif",
                          },
                          notchedOutline: { borderColor: "#E5E5E5" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E5E5E5",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E5E5E5",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#E5E5E5",
                            },
                          },
                        }}
                        onKeyDown={(event) => {
                          // Prevent negative values and scientific notation ('e') from being typed
                          if (event.key === "-" || event.key === "e") {
                            event.preventDefault();
                          }
                          handleKeyPress(event);
                        }}
                        onDrop={(event) => {
                          const data = event.dataTransfer.getData("text");
                          if (
                            data.includes("e") ||
                            data.includes("-") ||
                            isNaN(Number(data))
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          const data = event.clipboardData.getData("text");
                          if (
                            data.includes("e") ||
                            data.includes("-") ||
                            isNaN(Number(data))
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Box>
                    <Box className="flex-align-center" sx={{ gap: 1 }}>
                      <label className={styles.toLabel} htmlFor="To">
                      {text?.to[lang]}
                      </label>
                      <TextField
                        id="age-to"
                        label=""
                        variant="outlined"
                        type="number"
                        value={stagedFilters.toAge}
                        onChange={(event) => {
                          let value = event.target.value;
                          if (value === "") {
                            handleAgeChange("toAge", ""); // Allow clearing the input
                          } else {
                            value = Number(value);
                            if (value > 0) {
                              handleAgeChange("toAge", value);
                            } else if (value === 0) {
                              handleAgeChange("toAge", ""); // Start from 1 instead of 0
                            } else {
                              handleAgeChange("toAge", "");
                            }
                          }
                        }}
                        size="small"
                        InputProps={{
                          sx: {
                            height: "30px",
                            width: "48px",
                            fontSize: "14px",
                            fontFamily: "Brown, sans-serif",
                          },
                          notchedOutline: { borderColor: "#E5E5E5" },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E5E5E5",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E5E5E5",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#E5E5E5",
                            },
                          },
                        }}
                        onKeyDown={(event) => {
                          // Prevent negative values and scientific notation ('e') from being typed
                          if (event.key === "-" || event.key === "e") {
                            event.preventDefault();
                          }
                          handleKeyPress(event);
                        }}
                        onDrop={(event) => {
                          const data = event.dataTransfer.getData("text");
                          if (
                            data.includes("e") ||
                            data.includes("-") ||
                            isNaN(Number(data))
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          const data = event.clipboardData.getData("text");
                          if (
                            data.includes("e") ||
                            data.includes("-") ||
                            isNaN(Number(data))
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  {(ageError.fromAge ||
                    ageError.toAge ||
                    ageError.rangeError ||
                    ageError.emptyFieldError) && (
                    <div className={styles.errorText}>
                      {ageError.rangeError
                        ? text?.filter_errors.from_shouldnt_exceed_to[lang]
                        : ageError.emptyFieldError
                        ? text?.filter_errors.field_cannot_be_empty[lang]
                        : text?.filter_errors.age_between_18_to_60[lang]}
                    </div>
                  )}
                </Box>
              </div>
              <div className={styles.filterCategory}>
                <div className={styles.filterTitle}>{text?.gender[lang]}</div>
                <div className={styles.filterOption}>
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.gender.includes("male")}
                    onChange={() => handleCheckboxChange("male")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="male">{text?.male[lang]}</label>
                </div>
                <div className={styles.filterOption}>
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.gender.includes("female")}
                    onChange={() => handleCheckboxChange("female")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="female">{text?.female[lang]}</label>
                </div>
                <div className={styles.filterOption}>
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.gender.includes("other")}
                    onChange={() => handleCheckboxChange("other")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="non-binary">{text?.genders?.nonBinary[lang]}</label>
                </div>
              </div>

              <div className={styles.filterCategory}>
                <div className={styles.filterTitle}>{text?.status_text[lang]}</div>
                <div className={styles.filterOption}>
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.statuses.includes("Active")}
                    onChange={() => handleStatusCheckboxChange("Active")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="active">{text?.active[lang]}</label>
                </div>
                <div className={styles.filterOption}>
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.statuses.includes("Inactive")}
                    onChange={() => handleStatusCheckboxChange("Inactive")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="inactive">{text?.inactive[lang]}</label>
                </div>
              </div>
            </div>
            <button
              className={styles.applyFilterButton}
              onClick={handleApplyFilters}
              disabled={
                ageError.fromAge ||
                ageError.toAge ||
                ageError.rangeError ||
                ageError.emptyFieldError
              }
            >
              {text?.apply[lang]}
            </button>
          </Popover>
          <button
            className={styles.createUserButton}
            onClick={handleCreateParticipantClick}
          >
            {text?.add_participant[lang]}
          </button>
        </div>
        <CreateParticipantModal
          isOpen={isModalOpen}
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          responseMessage={responseMessage}
          isResponseSuccess={isResponseSuccess}
          setResponseMessage={setResponseMessage}
        />
      </div>
      <RDPParticipantsDetailsTable isUserCreated={isUserCreated} />
    </div>
  );
};

export default RDPUserTable;
