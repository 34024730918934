import { ThreeDots } from "react-loader-spinner";
import { Box } from "@mui/material";

const ThreeDotsLoader = ({ height = 20, width = 30, color = "#fff" }) => {
  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      className="flex-align-center-justify-center"
    >
      <ThreeDots
        height={height} // Adjust size as needed
        width={width} // Adjust size as needed
        color={color} // Set to gray as per your requirement
        ariaLabel="loading"
      />
    </Box>
  );
};

export default ThreeDotsLoader;
