// AdminUserTable.js
import React, { useCallback, useState } from "react";
import styles from "./AdminUserTable.module.css";
import UserDetailsTable from "../AdminUsersDetailsTable/UserDeatilsTable";
import CreateUserModal from "../../../utils/Modals/AdminModal/CreateUserModal/CreateUserModal";
import { createUser } from "../../apiRoutes/adminApi/admin-api";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import * as _ from "lodash";
import constants from "../../../constants";
import { Popover, Checkbox } from "@mui/material";
import { useImageAssets } from "../../../context/ImageAssetsContext";
import magnifyGlass from "../../../assets/svgicons/magnifyGlass.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CropSquareTwoToneIcon from "@mui/icons-material/CropSquareTwoTone";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";
import Banner from "../../../sharedComponents/Banner/Banner";

const AdminUserTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState("");
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const { selectedFilters, setSelectedFilters, authData, signOut } = useAuth();
  const [stagedFilters, setStagedFilters] = useState({ ...selectedFilters });
  const [responseMessage, setResponseMessage] = useState("");
  const [isResponseSuccess, setIsResponseSuccess] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterText, setFilterText] = useState("");
  const { imageAssetsData, loading } = useImageAssets();
  const [networkError, setNetworkError] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (clickOutside) => {
    setAnchorEl(null);
    if (clickOutside) setStagedFilters({ ...selectedFilters });
  };

  const open = Boolean(anchorEl);

  const handleCreateUserClick = () => {
    setIsModalOpen(true);
  };

  const handleModalConfirm = async (username, email, role, fullPhoneNumber) => {
    console.log(role);

    const idToken =
      authData?.data?.tokenresponse?.IdToken ||
      localStorage.getItem("id_token");
    const accessToken =
      authData?.data?.tokenresponse?.AccessToken ||
      localStorage.getItem("access_token");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        id_token: idToken,
      },
      timeout: 10000,
    };
    const requestBody = {
      useremail: email,
      userrole: role,
      name: username,
    };
    if (fullPhoneNumber) {
      requestBody["userphone"] = fullPhoneNumber;
    }

    try {
      const response = await axios.post(createUser, requestBody, config);
      console.log("User created successfully:", response.data);
      setIsUserCreated(response.data); 
      setResponseMessage(constants.USER_SUCCESS); // Set success message
      setIsResponseSuccess(true);
    } catch (error) {
      let errorMessage = "Failed to create user."; // Default error message
      console.log(error)
      if (error.response) {
        if (
          error.response?.data?.message ===
            constants.USER_ACCOUNT_ALREADY_EXISTS ||
          error.response?.data?.message ===
            constants.USER_ACCOUNT_ALREADY_EXISTS_AND_EXPIRED
        ) {
          errorMessage = constants.MOBILE_NUMBER_ALREADY_EXISTS;
        } else if (
          error.response.data.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else {
          errorMessage = `${
            error.response.data.Error || error.response.data.message
          }`;
        }
        setResponseMessage(errorMessage); // Set error message
        setIsResponseSuccess(false);
      } else if (error.message.includes("Network Error")) {
        setNetworkError(true)
        setIsResponseSuccess(false);
      } else if (error.code === 'ECONNABORTED') {
        // Timeout error
        console.error('Request timed out:', error.message);
        setNetworkError(true)
        setIsResponseSuccess(false);
      } 
    }
  };

  const handleCheckboxChange = (filterValue) => {
    const isSelected = stagedFilters.roles.includes(filterValue);
    let updatedRoles;

    if (isSelected) {
      updatedRoles = stagedFilters.roles.filter((f) => f !== filterValue);
    } else {
      updatedRoles = [...stagedFilters.roles, filterValue];
    }

    setStagedFilters({ ...stagedFilters, roles: updatedRoles }); // Update the temporary staged filters state
  };

  const handleStatusCheckboxChange = (statusValue) => {
    const isSelected = stagedFilters.statuses.includes(statusValue);
    let updatedStatuses;

    if (isSelected) {
      updatedStatuses = stagedFilters.statuses.filter((s) => s !== statusValue);
    } else {
      updatedStatuses = [...stagedFilters.statuses, statusValue];
    }

    setStagedFilters({ ...stagedFilters, statuses: updatedStatuses }); // Update the staged filters state
  };

  const handleApplyFilters = () => {
    setSelectedFilters(stagedFilters); // Apply the staged filters
    console.log(selectedFilters);
    setIsFilterDropdownOpen(false);
    console.log(stagedFilters);
    handleClose(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const debounceFuncUserFilter = useCallback(
    _.debounce((value) => {
      const obj = { ...stagedFilters, inputValue: value };
      setStagedFilters(obj);
      setSelectedFilters(obj);
    }, 500),
    [stagedFilters]
  );

  const handleFilterText = (value) => {
    const regex = /^[a-zA-Z0-9]+$/;

    if (value === "") {
      setFilterText(value);
      debounceFuncUserFilter(value);
    }
    // If the input is not empty, check the regex and length constraints
    else if (value && regex.test(value) && value.length <= 15) {
      setFilterText(value);
      debounceFuncUserFilter(value);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className={styles.userDetailsContainer}>
      {networkError && <Banner message={text?.validations?.something_went_wrong_refresh[lang]} onRefresh={handleRefresh} action={text?.refresh[lang]}/>}
      <div className={styles.headerSection}>
        <h1 className={styles.title}>{text?.user_details[lang]}</h1>
        <div className={styles.controls}>
          <div className={styles.searchInputContainer}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={text?.search_by_uid_name[lang]}
              onChange={(e) => handleFilterText(e.target.value)}
              value={filterText}
            />
            {filterText && filterText?.length > 0 ? (
              <CloseRoundedIcon
                className={styles.clearIcon}
                fontSize="small"
                onClick={() => handleFilterText("")}
              />
            ) : (
              <img
                src={magnifyGlass}
                className={styles.magnifyGlassIcon}
                alt="X"
                draggable="false"
              />
            )}
          </div>
          <div className={styles.filterSection} onClick={handleClick}>
            <div className={styles.iconTextWrapper}>
              <FilterListRoundedIcon
                fontSize="small"
                className={styles.filterIcon}
              />
              <span className={styles.filterText}>{text?.filter[lang]}</span>
            </div>
          </div>

          <Popover
            id={"filter-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={() => handleClose(true)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: "12px",
                  mt: 1,
                  width: "294px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Custom box-shadow
                },
              },
            }}
          >
            <div className={styles.filterContentWrapper}>
              <div className={styles.filterHeader}>{text?.filter_by[lang]} :</div>
              <div className={styles.filterCategory}>
                <div className={styles.filterTitle}>{text?.role[lang]}</div>
                <div
                  className={styles.filterOption}
                  onClick={() => handleCheckboxChange("Raw Data Provider")}
                >
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.roles.includes("Raw Data Provider")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="rawDataProvider">{text?.roles["Raw Data Provider"]?.[lang]}</label>
                </div>
                <div
                  className={styles.filterOption}
                  onClick={() => handleCheckboxChange("Urban Planner")}
                >
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.roles.includes("Urban Planner")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="urbanPlanner">{text?.roles["Urban Planner"]?.[lang]}</label>
                </div>
                <div
                  className={styles.filterOption}
                  onClick={() => handleCheckboxChange("Admin")}
                >
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.roles.includes("Admin")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="admin">{text?.roles["Admin"]?.[lang]}</label>
                </div>
              </div>
              <div className={styles.filterCategory}>
                <div className={styles.filterTitle}>{text?.status_text[lang]}</div>
                <div
                  className={styles.filterOption}
                  onClick={() => handleStatusCheckboxChange("Active")}
                >
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.statuses.includes("Active")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="active">{text?.active[lang]}</label>
                </div>
                <div
                  className={styles.filterOption}
                  onChange={() => handleStatusCheckboxChange("Inactive")}
                >
                  <Checkbox
                    icon={<CropSquareTwoToneIcon fontSize="medium" />}
                    checked={stagedFilters.statuses.includes("Inactive")}
                    checkedIcon={
                      <img
                        src={imageAssetsData["checkedBoxIcon.png"]}
                        height={16}
                        width={16}
                        style={{ margin: 4 }}
                      />
                    }
                  />
                  <label htmlFor="inactive">{text?.inactive[lang]}</label>
                </div>
              </div>
            </div>
            <button
              className={styles.applyFilterButton}
              onClick={handleApplyFilters}
            >
              {text?.apply[lang]}
            </button>
          </Popover>
          <button
            className={styles.createUserButton}
            onClick={handleCreateUserClick}
          >
            {text?.create_user[lang]}
          </button>
        </div>
        <CreateUserModal
          isOpen={isModalOpen}
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          responseMessage={responseMessage}
          isResponseSuccess={isResponseSuccess}
          setResponseMessage={setResponseMessage}
        />
      </div>
      <UserDetailsTable isUserCreated={isUserCreated} />
    </div>
  );
};

export default AdminUserTable;
