import React, { useState, useRef, useEffect } from "react";
import styles from "./OTPInputComponent.module.css";
import axios from "axios";
import { useAuth } from "../../../../context/AuthContext";
import {
  loginParticipant,
  signInParticipant,
} from "../../../apiRoutes/participantApi/participant-api";
import { login } from "../../../../utils/AuthFile/Auth";
import "../../../../font.css";
import { ThreeDots } from "react-loader-spinner";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { Checkbox } from "@mui/material";
import constants from "../../../../constants";
import { text } from "../../../../constant/text.js";
import { lang } from "../../../../constant/Var.js";

const OTPInputComponent = ({ otpsVerified, mobileNumber }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isValidOtp, setIsValidOtp] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const inputsRef = useRef([]);
  const [isDataPrivacyChecked, setIsDataPrivacyChecked] = useState(false);
  const [autoFocusOTP, setAutoFocusOTP] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isAccountDisabled, setIsAccountDisabled] = useState(false);

  const isOtpComplete = otp.every((digit) => digit.trim() !== "");
  const [timeLeft, setTimeLeft] = useState(59);
  const { setAuth, loginInfo } = useAuth();

  useEffect(() => {
    if (autoFocusOTP) {
      inputsRef.current[0].focus();
      setAutoFocusOTP(false);
    }
  }, [autoFocusOTP]);

  useEffect(() => {
    window.timer = setInterval(() => {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const startingTime = localStorage.getItem("timer");
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(window.timer);
          setShowRegenerate(true);
          setIsInputDisabled(true);
          return 0;
        }
        return startingTime - currentTime;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(window.timer);
  }, [timeLeft]);
  useEffect(() => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    localStorage.setItem("timer", currentTime + 59);
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    if (!/^\d$/.test(element.value)) {
      return false;
    }
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    setIsValidOtp(true);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyUp = (event, index) => {
    if (event.key === "Backspace") {
      const newOtp = [...otp];

      if (otp[index] === "") {
        if (index > 0) {
          newOtp[index - 1] = "";
          inputsRef.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
      }
      setOtp(newOtp);
    }
  };

  const handleSignIn = async (e) => {
    setIsValidOtp(true);
    setIsNetworkError(false)
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);

    // Clear the countdown timer
    clearInterval(window.timer);

    const otpValue = otp.join("");

    try {
      const response = await axios.post(signInParticipant, {
        phone_number: mobileNumber,
        otp: otpValue,
        session: localStorage.getItem("otp_session"),
      });
      setIsOtpValid(true);
      if (response.data) {
        if (response.data.success) {
          otpsVerified();
        }
        loginInfo();
        login();
      }
      setAuth(response.data);
      localStorage.removeItem("timer");
    } catch (error) {
      console.error(error)
      if (error.message === "Network Error") {
        setShowRegenerate(true);
        setIsInputDisabled(true);
        setIsNetworkError(true);
      } else if (error.response.data.message.toLowerCase().includes(constants.FAILED_TO_GENERATE_TOKEN)){
        setIsNetworkError(false);
        setIsAccountDisabled(true);
        setShowRegenerate(false);
        setIsInputDisabled(true);
      } else{
        setIsValidOtp(false);
        setIsNetworkError(false);
        setShowRegenerate(true);
        setIsInputDisabled(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegenerateOtp = async () => {
    setIsValidOtp(true);
    setIsNetworkError(false)
    setIsLoading(true)
    try {
      const response = await axios.post(loginParticipant, {
        phone_number: mobileNumber,
      });
      localStorage.setItem(
        "otp_session",
        response.data.data.login_response.data.Session
      );
      const currentTime = Math.floor(new Date().getTime() / 1000);
      localStorage.setItem("timer", currentTime + 59);
      setTimeLeft(59);
      setOtp(new Array(6).fill(""));
      setIsValidOtp(true);
      setIsInputDisabled(false);
      setShowRegenerate(false);
      setIsDataPrivacyChecked(false);
      setIsLoading(false)
      if (response?.data) {
        setAutoFocusOTP(true);
      }
    } catch (error) {
      console.error(error)
      if (error.message === "Network Error") {
        setIsValidOtp(true)
        setShowRegenerate(true);
        setIsNetworkError(true)
        setIsInputDisabled(true);
      } else if (error.response.data.message.toLowerCase().includes(constants.FAILED_TO_GENERATE_TOKEN) || error.response.data.message.includes("Access is denied for this User !")){
        localStorage.removeItem("timer");
        setIsNetworkError(false)
        setIsAccountDisabled(true)
        setShowRegenerate(false);
        setIsInputDisabled(true);
      } else{
        setIsNetworkError(false)
        setShowRegenerate(false);
        setIsInputDisabled(true);
      }  
      setIsLoading(false)
    }
  };
  return (
    <div className={styles.otpContainer}>
      <div className={styles.otpTitle}>
        <span className={styles.otpLabels}>{text?.enter_otp[lang]}</span>

        {showRegenerate ? (
          <span>
            {/* "Resend OTP" text and timer are hidden when showRegenerate is true */}
          </span>
        ) : (
          <span className={styles.resendOTPMsg}>
            {text?.resend_otp[lang]}{" "}
            <span style={{ color: "#EBC03F" }}>
            {text?.in[lang]}{" "}
              {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? "0" : ""}${
                timeLeft % 60
              } `}{" "}
              {Math.floor(timeLeft / 60) > 0 ? "min" : "min"}
            </span>
          </span>
        )}
      </div>
      {/* OTP input fields */}
      <div className={styles.otpInputs}>
        {otp.map((digit, index) => (
          <input
            key={index}
            type="password"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(e.target, index)}
            onKeyUp={(e) => handleKeyUp(e, index)}
            onFocus={(e) => e.target.select()}
            ref={(el) => (inputsRef.current[index] = el)}
            disabled={isInputDisabled || isLoading}
            className={`${styles.otpInput} ${
              !isValidOtp
                ? styles.invalidOtpInput
                : isOtpValid
                ? styles.validOtpInput
                : ""
            }`}
            style={{ backgroundColor: isOtpValid ? "#D2E9D4" : "" }} // Conditional inline style for valid OTP
            autoFocus={index == 0}
          />
        ))}
      </div>
      {!isValidOtp && <div className={styles.error}>{text?.validations.invalid_otp[lang]}</div>}
      {isNetworkError && <div className={styles.error}>{text?.validations.something_is_wrong[lang]}</div>}
      {isAccountDisabled && <div className={styles.error}>{text?.validations.number_is_disabled[lang]}</div>}

      <div className={styles.consentContainerParticipant}>
        <Checkbox
          id="privacyCheckbox"
          checked={isDataPrivacyChecked}
          onChange={(e) => setIsDataPrivacyChecked(e.target.checked)}
          className={styles.privacyPolicycheckBox}
          disabled={showRegenerate || isLoading || isAccountDisabled}
          icon={<CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}
          checkedIcon={<CheckBoxOutlinedIcon fontSize="small" />}
          sx={{
            color: "#A2A2A2",
            "&.Mui-checked": {
              color: "#016F4A",
            },
            "&.Mui-disabled": {
              color: "#A2A2A2",
            },
          }}
        />
        <label
          htmlFor="privacyCheckbox"
          className={`${styles.consentText} ${
            isDataPrivacyChecked ? styles.checkedConsentText : ""
          } ${showRegenerate || isLoading || isAccountDisabled? styles.disabledConsentText : ""}`}
        >
          {text?.participant_consent_text[lang]}
        </label>
      </div>
      <div className={styles.btnGrp}>
        {!showRegenerate && (
          <button
            className={`${styles.verifyButton} ${
              isOtpComplete && isDataPrivacyChecked && !isLoading && !isAccountDisabled
                ? styles.verifyButtonActive
                : styles.verifyButtonInactive
            }`}
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "flex-end",
            }}
            onClick={handleSignIn}
            disabled={
              !isOtpComplete ||
              !isDataPrivacyChecked ||
              isInputDisabled ||
              isAccountDisabled ||
              isLoading
            }
          >
            {isLoading ? (
              <ThreeDots
                height="20" // Adjust size as needed
                width="30" // Adjust size as needed
                color="#fff" // Set to gray as per your requirement
                ariaLabel="loading"
              />
            ) : (
              text?.sign_in[lang]
            )}
          </button>
        )}
        {showRegenerate && (
          <button
          className={styles.regenerateButton}
          onClick={handleRegenerateOtp}
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          {isLoading ? (
            <ThreeDots
              height="20" // Adjust size as needed
              width="30" // Adjust size as needed
              color="#fff" // Set to gray as per your requirement
              ariaLabel="loading"
            />
          ) : (
            text?.regenerate_otp[lang]
          )}
        </button>
        )}
      </div>
    </div>
  );
};

export default OTPInputComponent;
