// ParticipantLayout.js
import React, { useEffect } from "react";
import styles from "../ParticipantLogin.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { PARTICIPANT_PROFILE_VIEW } from "../../../apiRoutes/participantRoutes/participantsRoutes";
import { RDP_DASHBOARD } from "../../../apiRoutes/rdpParticipantRoutes/rdpParticipantRoutes";
import constants from "../../../../constants";
import { useImageAssets } from "../../../../context/ImageAssetsContext";
import neomLogo2 from "../../../../assets/images/neomUpdatedLogo.png"

const ParticipantLayout = () => {
  const { loggedIn, profileData, authData, profileStatus, role } = useAuth();

  const navigate = useNavigate();
  const { imageAssetsData, loading } = useImageAssets();

  useEffect(() => {
    if (loggedIn) {
      if (profileData && profileStatus?.success && authData) {
        if (role === constants.RDP) {
          navigate(RDP_DASHBOARD);
        } else navigate(PARTICIPANT_PROFILE_VIEW);
      }
    }
  }, [loggedIn, authData, profileData, profileStatus, navigate]);

  return (
    <div className={styles.container}>
      <div
        className={styles.imageSection}
        style={{ backgroundImage: `url(${imageAssetsData["Sign_in-BG.pn"]})` }}
      >
        <img
          src={neomLogo2}
          alt="Neom Logo"
          className={styles.logo}
          draggable="false"
        />
      </div>

      <Outlet />
    </div>
  );
};

export default ParticipantLayout;
