import React, { useEffect, useState } from "react";
import { Popover, Checkbox } from "@mui/material";
import CropSquareTwoToneIcon from "@mui/icons-material/CropSquareTwoTone";
import styles from "./AdminFilterModal.module.css";
import { useAuth } from "../../../../context/AuthContext";
import { useImageAssets } from "../../../../context/ImageAssetsContext";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const AdminFilterModal = ({ isOpen, onClose }) => {
  const { selectedFilters, setSelectedFilters } = useAuth();
  const [stagedFilters, setStagedFilters] = useState({ ...selectedFilters });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [applyClicked, setApplyClicked] = useState(false);
  const {imageAssetsData,loading} = useImageAssets();

  const handleCheckboxChange = (filterValue) => {
    const isSelected = stagedFilters.roles.includes(filterValue);
    let updatedRoles;

    if (isSelected) {
      updatedRoles = stagedFilters.roles.filter((f) => f !== filterValue);
    } else {
      updatedRoles = [...stagedFilters.roles, filterValue];
    }

    setStagedFilters({ ...stagedFilters, roles: updatedRoles }); // Update the temporary staged filters state
  };

  const handleStatusCheckboxChange = (statusValue) => {
    const isSelected = stagedFilters.statuses.includes(statusValue);
    let updatedStatuses;

    if (isSelected) {
      updatedStatuses = stagedFilters.statuses.filter((s) => s !== statusValue);
    } else {
      updatedStatuses = [...stagedFilters.statuses, statusValue];
    }

    setStagedFilters({ ...stagedFilters, statuses: updatedStatuses }); // Update the staged filters state
  };

  useEffect(() => {
    if (isOpen) {
      setAnchorEl(document.body); // Set anchor to the body or another element as needed
      if (!applyClicked) {
        setStagedFilters({ ...selectedFilters });
      }
    } else {
      setAnchorEl(null);
    }
    // Reset applyClicked only after the popover is fully closed
    return () => {
      setApplyClicked(false);
    };
  }, [isOpen, selectedFilters, applyClicked]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose(); // Ensuring the onClose passed from parent is called
  };

  const handleApplyFilters = () => {
    setSelectedFilters(stagedFilters);
    handleClose();
  };
  useEffect(() => {
    if (isOpen) {
      setAnchorEl(document.body); // Or any other element that you want to anchor to
    } else {
      setAnchorEl(null);
    }
  }, [isOpen]);

  return (
    <>
      {open && <div className={styles.overlay}></div>}
      <Popover
        id={"filter-popover"}
        open={isOpen}
        onClose={handleClose} // This handles closing for all cases
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            // Targeting the Paper component inside Popover
            borderRadius: "12px", // Apply border-radius
            mt: "40%", // Margin top (you can adjust the value as needed)
            boxShadow: "0px 4px 10px 6px rgba(0, 0, 0, 0.1)", // Custom box shadow
          },
        }}
        slotProps={{
          paper: { sx: { borderRadius: "12px", mt: 1, width: "264px" } },
        }}
      >
        <div className={styles.filterContentWrapper}>
          <div className={styles.filterHeader}>{text?.filter_by[lang]} :</div>
          <div className={styles.filterCategory}>
            <div className={styles.filterTitle}>{text?.role[lang]}</div>
            <div className={styles.filterOption}
            onClick={() => handleCheckboxChange("Raw Data Provider")}
            >
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.roles.includes("Raw Data Provider")}
                checkedIcon={
                  <img
                    src={imageAssetsData['checkedBoxIcon.png']}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="rawDataProvider">{text?.roles["Raw Data Provider"]?.[lang]}</label>
            </div>
            <div className={styles.filterOption}
            onClick={() => handleCheckboxChange("Urban Planner")}
            >
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.roles.includes("Urban Planner")}
                checkedIcon={
                  <img
                    src={imageAssetsData['checkedBoxIcon.png']}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="urbanPlanner">{text?.roles["Urban Planner"]?.[lang]}</label>
            </div>
            <div className={styles.filterOption}
            onClick={() => handleCheckboxChange("Admin")}
            >
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.roles.includes("Admin")}
                checkedIcon={
                  <img
                    src={imageAssetsData['checkedBoxIcon.png']}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="admin">{text?.roles["Admin"]?.[lang]}</label>
            </div>
          </div>
          <div className={styles.filterCategory}>
            <div className={styles.filterTitle}>{text?.status_text[lang]}</div>
            <div className={styles.filterOption}
                onClick={() => handleStatusCheckboxChange("Active")}
            >
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.statuses.includes("Active")}
                checkedIcon={
                  <img
                    src={imageAssetsData['checkedBoxIcon.png']}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="active">{text?.active[lang]}</label>
            </div>
            <div className={styles.filterOption}
             onChange={() => handleStatusCheckboxChange("Inactive")}

            >
              <Checkbox
                icon={<CropSquareTwoToneIcon fontSize="medium" />}
                checked={stagedFilters.statuses.includes("Inactive")}
                checkedIcon={
                  <img
                    src={imageAssetsData['checkedBoxIcon.png']}
                    height={16}
                    width={16}
                    style={{ margin: 4 }}
                  />
                }
              />
              <label htmlFor="inactive">{text?.inactive[lang]}</label>
            </div>
          </div>
        </div>
        <button
          className={styles.applyFilterButton}
          onClick={handleApplyFilters}
        >
          {text?.apply[lang]}
        </button>
      </Popover>
    </>
  );
};

export default AdminFilterModal;
