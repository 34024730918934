import React, { useState } from "react";
import styles from "./AdminLogin.module.css";
import { ThreeDots } from "react-loader-spinner";
import EmailOtpComponent from "../AdminEmailOtpAuth/EmailOtpComponent"; // Ensure this path is correct
import axios from "axios";
import { loginUser } from "../../apiRoutes/adminApi/admin-api";
import { isValidEmail } from "../../../utils/helpers";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";
import { useAuth } from "../../../context/AuthContext";

const AdminLogin = () => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [isButtondisabled, setIsButtonDisabled] = useState(false)
  const { setRoleId } = useAuth();

  const handleGetOTP = () => {
    // Manually trigger form validation
    if (email?.length) {
      // No errors, proceed with OTP
      setIsVerifying(true); // Start the loader
      setEmailError(""); // Clear any previous error messages

      const apiUrl = loginUser;
      const emailData = {
        email: email,
      };

      axios
        .post(apiUrl, emailData)
        .then((response) => {
          localStorage.setItem(
            "super_admin_otp_session",
            response.data.data.login_response.data.Session
          );
          setIsEmailVerified(true);
          setRoleId("admin")
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setEmailError(text?.validations.something_is_wrong[lang]);
          } else if (error.response?.data?.message.includes("Access is denied for this User !")) {
            setEmailError(text?.validations.account_is_disabled[lang]);
            setIsButtonDisabled(true)
          } else {
            setEmailError(text?.validations.account_not_found[lang]);
            setIsButtonDisabled(true)
          }
        })
        .finally(() => {
          setIsVerifying(false);
        });
    }
  };

  const handleOnEnteredPress = (e) => {
    if (emailValid && e?.key === "Enter") {
      handleGetOTP();
    }
  };

  const handleEmail = (e) => {
    const input = e?.target?.value.replace(/\s+/g, "");
    if (input === "") {
      setEmailError("");
      setEmailValid(false);
    } else if (isValidEmail(input)) {
      setEmailValid(true);
      setEmailError("");
      setIsButtonDisabled(false)
    } else {
      setEmailValid(false);
      setEmailError(text?.validations.invalid_email_address[lang]);
    }
    
    setEmail(input);
  };

  return (
    <div className={styles.signInSection}>
      <div className={styles.signInBox}>
        <h1 className={styles.signinTitle}>{text?.virtual_humans[lang]}</h1>
        <div className={styles.inputGroup}>
          <h3 className={styles.signInHeading}>{text?.sign_in[lang]}</h3>
          <div className={styles.fieldContainer}>
            <label htmlFor="email" className={styles.fieldLabel}>
            {text?.email_id[lang]}
            </label>
            <input
              id="email"
              type="text"
              value={email}
              onChange={handleEmail}
              className={`${styles.textField} ${emailError ? styles.inputError : ""}`}
              placeholder={text?.enter_email_id[lang]}
              onKeyPress={handleOnEnteredPress}
              disabled={isEmailVerified||isVerifying}
            />
            {emailError && <div className={styles.error}>{emailError}</div>}
          </div>
        </div>
        <div className={styles.buttonStyle}>
          {!isEmailVerified && (
            <button
              type="button"
              className={`${styles.otpButton} ${
                (emailValid&&!isButtondisabled) ? styles.otpButtonActive : ""
              }`}
              onClick={() => handleGetOTP()}
              disabled={isVerifying || !emailValid || isButtondisabled}
              style={{ justifyContent: "center" }}
            >
              {isVerifying ? (
                <ThreeDots
                  height="20"
                  width="30"
                  color="#fff"
                  ariaLabel="loading"
                />
              ) : (
                text?.get_otp[lang]
              )}
            </button>
          )}
        </div>
        {isEmailVerified && (
          <EmailOtpComponent email={email} emailValid={emailValid} />
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
