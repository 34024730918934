import React from "react";
import styles from "./DeactivateModal.module.css"; 
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const DeactivateModal = ({ isOpen, onCancel, onConfirm, userid, msg }) => {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(userid); // Pass the userid back to the confirmation handler
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{text?.change_status[lang]}</h2>
        <span className={styles.modalInfo}>
          {msg} {userid}
        </span>
        <div className={styles.modalActions}>
          <button onClick={onCancel} className={styles.modalCancel}>
            {text?.close[lang]}
          </button>
          <button onClick={handleConfirm} className={styles.modalConfirm}>
            {text?.confirm[lang]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeactivateModal;
