import React from "react";

const ActiveHomeIcon = ({ styles }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7066 2.29279C12.5191 2.10532 12.2648 2 11.9996 2C11.7344 2 11.4801 2.10532 11.2926 2.29279L4.29259 9.29279L2.29259 11.2928C2.19708 11.385 2.1209 11.4954 2.06849 11.6174C2.01608 11.7394 1.9885 11.8706 1.98734 12.0034C1.98619 12.1362 2.01149 12.2678 2.06177 12.3907C2.11205 12.5136 2.18631 12.6253 2.2802 12.7192C2.37409 12.8131 2.48574 12.8873 2.60864 12.9376C2.73154 12.9879 2.86321 13.0132 2.99599 13.012C3.12877 13.0109 3.25999 12.9833 3.382 12.9309C3.504 12.8785 3.61435 12.8023 3.70659 12.7068L3.99959 12.4138V18.9998C3.99959 19.7954 4.31566 20.5585 4.87827 21.1211C5.44088 21.6837 6.20394 21.9998 6.99959 21.9998H16.9996C17.7952 21.9998 18.5583 21.6837 19.1209 21.1211C19.6835 20.5585 19.9996 19.7954 19.9996 18.9998V12.4138L20.2926 12.7068C20.4812 12.8889 20.7338 12.9897 20.996 12.9875C21.2582 12.9852 21.509 12.88 21.6944 12.6946C21.8798 12.5092 21.985 12.2584 21.9873 11.9962C21.9895 11.734 21.8888 11.4814 21.7066 11.2928L12.7066 2.29279Z"
      fill="#EBC03F"
    />
  </svg>
);

export default ActiveHomeIcon;
