import React from "react";

const SoundInactiveIcon = ({ styles }) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={styles}
  >
    <path
      d="M1.1063 7.28693C0.873155 6.89824 0.75 6.45351 0.75 6.00026C0.75 5.54701 0.873155 5.10228 1.1063 4.71359C1.178 4.59394 1.27393 4.49061 1.38794 4.41024C1.50195 4.32987 1.63151 4.27424 1.7683 4.24693L2.89697 4.02093C2.96428 4.0076 3.02499 3.9716 3.06897 3.91893L4.44764 2.26359C5.23564 1.31693 5.6303 0.844259 5.98164 0.971592C6.3343 1.09893 6.3343 1.71493 6.3343 2.94693V9.05493C6.3343 10.2863 6.3343 10.9016 5.9823 11.0296C5.63097 11.1563 5.2363 10.6836 4.4483 9.73759L3.06764 8.08159C3.02382 8.02905 2.96337 7.99306 2.8963 7.97959L1.76764 7.75359C1.63085 7.72628 1.50128 7.67065 1.38727 7.59028C1.27326 7.50991 1.178 7.40658 1.1063 7.28693Z"
      stroke="#E5E5E5"
      stroke-width="1.33333"
    />
    <path
      d="M9.35742 3.64258C9.97944 4.26457 10.3304 5.10723 10.3338 5.98687C10.3371 6.86651 9.99265 7.71183 9.37542 8.33858"
      stroke="#E5E5E5"
      stroke-width="1.33333"
      stroke-linecap="round"
    />
  </svg>
);

export default SoundInactiveIcon;
