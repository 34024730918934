import React from "react";
import Select, { components } from "react-select";
import DropDownLight from "../../assets/svgicons/DropDownLight";
import { text } from "../../constant/text";
import { lang } from "../../constant/Var";

const DropdownSelect = ({
  style,
  options,
  value,
  onChange,
  label,
  isMulti = false,
  color,
  border,
  indicatorstyle,
  size,
  placeholder,
  isSubmitted,
}) => {
  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {props.heading && (
          <div
            style={{
              padding: "8px 18px",
              fontWeight: 400,
              fontFamily: "Brown, sans-serif",
              fontSize: "12px",
              backgroundColor: "#FFFFFF",
            }}
          >
            {props.heading}
          </div>
        )}
        {props.children}
      </components.MenuList>
    );
  };

  const DropdownIndicator = (props) => {
    if (isSubmitted) return <></>; // Hide the indicator when isSubmitted is true
    return (
      <components.DropdownIndicator {...props}>
        <DropDownLight
          styles={{ marginRight: indicatorstyle ? indicatorstyle : 5 }}
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className="block"
      style={{
        ...style,
        cursor: isSubmitted ? "not-allowed" : "pointer",
      }}
    >
      <Select
        className="dropdown"
        value={options.find((option) => option.value === value) || null}
        onChange={onChange}
        options={options}
        isMulti={isMulti}
        isDisabled={isSubmitted}
        placeholder={
          <div style={{ fontSize: "14px" }}>
            {placeholder ? placeholder : text?.select[lang] + "..."}
          </div>
        }
        components={{
          MenuList: (props) => <CustomMenuList {...props} heading={label} />,
          DropdownIndicator,
        }}
        styles={{
          control: (styles) => ({
            ...styles,
            fontFamily: "Brown, sans-serif",
            fontWeight: "400",
            "@media only screen and (max-width: 768px)": {
              height: "44px",
            },
            "@media only screen and (min-width: 768px)": {
              height: "44px",
            },
            backgroundColor: color,
            borderColor: "#FFFFFF",
            boxShadow: "none", // Remove focus box shadow
            border: border ? border : "1px solid #E5E5E5", // Keep the border color consistent
            "&:hover": {
              borderColor: "#E5E5E5", // Maintain the border color on hover
            },
          }),
          option: (styles, { isFocused }) => ({
            ...styles,
            paddingLeft: "30px",
            paddingRight: "30px",
            backgroundColor: isFocused ? "#E5E5E5" : "transparent", // Change hover color to #E5E5E5
            color: "#000", // Ensure the text color remains readable
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: "#FFFFFF !important", // Set background color to white
            marginTop: "-0%", // Adjust the margin to move the dropdown upwards
            width: "fit-content",
            marginLeft: "5px",
            border: "0", // Remove the border
            borderRadius: "8px",
            boxShadow:
              "0 8px 16px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.05)", // Add shadow on all sides
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            display: "none", // Hide the indicator separator
          }),
        }}
      />
    </div>
  );
};

export default DropdownSelect;
