import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { amazon_bucket_url } from "../constant/Var";

const ImageAssetsContext = createContext({});

export const ImageAssetsProvider = ({ children }) => {
  const [imageAssetsData, setImageAssetsData] = useState(() => {
    // Attempt to load cached data from local storage
    const localData = localStorage.getItem("localImageAssetsData");
    return localData ? JSON.parse(localData) : {};
  });
  const [loading, setLoading] = useState(false);

  const fetchImageAssets = useCallback(async () => {
    setLoading(true);
    console.log("Fetching image assets...");
    try {
      let localImageAssetsData=localStorage.getItem("localImageAssetsData");
      if(localImageAssetsData){
        localImageAssetsData=JSON.parse(localImageAssetsData)
        setImageAssetsData(localImageAssetsData);
      }else{
        const response = await axios.get(amazon_bucket_url);
        console.log("Fetched data:", response.data);
        setImageAssetsData(response.data);
        localStorage.setItem("localImageAssetsData", JSON.stringify(response.data));
      }
      
    } catch (error) {
      console.error("Failed to fetch image assets:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Fetch only if local data is not available
    if (Object.keys(imageAssetsData).length === 0) {
      fetchImageAssets();
    }

    const interval = setInterval(() => {
      fetchImageAssets();
    }, 1800000); // Refresh every 30 minutes

    return () => {
      console.log("Clearing interval on component unmount");
      clearInterval(interval);
    };
  }, [fetchImageAssets]);

  return (
    <ImageAssetsContext.Provider value={{ imageAssetsData, loading }}>
      {children}
    </ImageAssetsContext.Provider>
  );
};

export const useImageAssets = () => useContext(ImageAssetsContext);
