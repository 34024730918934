import React from "react";

const ExpandIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.49967 5.50016L1.33301 1.3335M1.33301 1.3335V4.66683M1.33301 1.3335H4.66634M10.4997 5.50016L14.6663 1.3335M14.6663 1.3335V4.66683M14.6663 1.3335H11.333M5.49967 10.5002L1.33301 14.6668M1.33301 14.6668V11.3335M1.33301 14.6668H4.66634M10.4997 10.5002L14.6663 14.6668M14.6663 14.6668V11.3335M14.6663 14.6668H11.333"
      stroke="#5E5E5E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ExpandIcon;
