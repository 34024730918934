import React from "react";
import { Checkbox } from "@mui/material";

const CheckboxParameter = ({ checked, onChange, disabled, style, color }) => {
  
  return (
    <div className="checkboxWrapper" style={style ? style : {}}>
      <Checkbox
        size="small"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        sx={{
          '&.Mui-checked': {
            color: "#ebc03f",
            cursor: 'not-allowed',
          },'&.Mui-disabled': {
            color: color ? color : "#E5E5E5",
            borderColor: color ? color : "#E5E5E5",
          },
        }}
      />
    </div>
  );
};

export default CheckboxParameter;
