import React from "react";

const TemperatureActiveIcon = ({ styles }) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={styles}
  >
    <path
      d="M3.75 1.75C3.31903 1.75 2.9057 1.9212 2.60095 2.22595C2.29621 2.5307 2.125 2.94402 2.125 3.375V8.252L1.9685 8.429C1.66626 8.77182 1.46935 9.19457 1.4014 9.64652C1.33345 10.0985 1.39734 10.5604 1.58541 10.977C1.77349 11.3935 2.07775 11.7469 2.4617 11.9949C2.84565 12.2428 3.29297 12.3746 3.75 12.3746C4.20703 12.3746 4.65436 12.2428 5.03831 11.9949C5.42225 11.7469 5.72652 11.3935 5.91459 10.977C6.10266 10.5604 6.16656 10.0985 6.09861 9.64652C6.03065 9.19457 5.83374 8.77182 5.5315 8.429L5.375 8.252V3.375C5.375 2.94402 5.2038 2.5307 4.89905 2.22595C4.5943 1.9212 4.18098 1.75 3.75 1.75ZM0.875002 3.375C0.875002 2.6125 1.1779 1.88124 1.71707 1.34207C2.25624 0.802901 2.9875 0.5 3.75 0.5C4.5125 0.5 5.24377 0.802901 5.78293 1.34207C6.3221 1.88124 6.625 2.6125 6.625 3.375V7.792C7.03718 8.32871 7.2909 8.97008 7.35744 9.64352C7.42397 10.317 7.30067 10.9956 7.00149 11.6026C6.70231 12.2096 6.2392 12.7207 5.66456 13.0781C5.08993 13.4355 4.42672 13.6249 3.75 13.6249C3.07328 13.6249 2.41007 13.4355 1.83544 13.0781C1.26081 12.7207 0.79769 12.2096 0.498509 11.6026C0.199329 10.9956 0.0760294 10.317 0.142567 9.64352C0.209104 8.97008 0.462821 8.32871 0.875002 7.792V3.375ZM3.125 5.125C3.125 4.95924 3.19085 4.80027 3.30806 4.68306C3.42527 4.56585 3.58424 4.5 3.75 4.5C3.91576 4.5 4.07473 4.56585 4.19194 4.68306C4.30915 4.80027 4.375 4.95924 4.375 5.125V8.636C4.68614 8.77861 4.93915 9.02333 5.09203 9.32955C5.24492 9.63577 5.28849 9.98506 5.2155 10.3194C5.14252 10.6538 4.95738 10.9532 4.6908 11.1679C4.42423 11.3825 4.09226 11.4996 3.75 11.4996C3.40774 11.4996 3.07577 11.3825 2.8092 11.1679C2.54263 10.9532 2.35748 10.6538 2.2845 10.3194C2.21152 9.98506 2.25509 9.63577 2.40797 9.32955C2.56086 9.02333 2.81387 8.77861 3.125 8.636V5.125Z"
      fill="#686868"
    />
  </svg>
);

export default TemperatureActiveIcon;
