import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const CustomSlider = styled(Slider)({
    width: "34.9%",  
    marginLeft: 5,
  '& .MuiSlider-thumb': {
    width: 9,  
    height: 14,
    backgroundColor: '#EBC03F',
    borderRadius: '21px',  
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(235, 192, 63, 0.16)',  // Optional hover effect
    },
    '&.Mui-active': {
      boxShadow: '0 0 0 14px rgba(235, 192, 63, 0.16)',  // Optional active effect
    },
  },
 '& .MuiSlider-track': {
    backgroundColor: '#EBC03F',  // Color of the filled portion of the slider
    height: 8,  // Adjust thickness of the filled line
    border: '2px solid #EBC03F',  // Border around the track line
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#FFEDC0',  // Color of the unfilled portion of the slider
    height: 8,  // Adjust thickness of the unfilled line
    border: '2px solid #FFEDC0',  // Border around the rail line
  },
});

export default CustomSlider;