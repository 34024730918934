import React from "react";
import styles from "./SessionTimeoutModal.module.css";
import { text } from "../../../constant/text";
import { lang } from "../../../constant/Var";

const SessionTimeoutModal = ({ isOpen, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2 className={styles.modalTitle}>{text?.session_timeout[lang]}</h2>
          <div className={styles.modalInfoContainer}>
            <span className={styles.modalInfo}>
              {text?.you_are_logged_outof_system[lang]}
            </span>
          </div>
          <div className={styles.modalActions}>
            <button onClick={onConfirm} className={styles.modalConfirm}>
              {text?.login[lang]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionTimeoutModal;
