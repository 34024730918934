import React, { useEffect } from "react";
import styles from "./WelcomeScreen.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import neomWelcomePlay from "../../../assets/videos/neom-welcome-video.mp4";
import neomLogo2 from "../../../assets/images/neomUpdatedLogo.png";
import "../../../font.css";
import { useAuth } from "../../../context/AuthContext";
import { ADMIN_LOGIN } from "../../apiRoutes/adminRoutes/adminRoutes";
import { text } from "../../../constant/text.js";
import { lang } from "../../../constant/Var.js";
import greaterIcon from '../../../assets/svgicons/greaterIcon.svg';


const WelcomeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn, authData, adminInfo } = useAuth();

  useEffect(() => {
    if (loggedIn && authData) {
      navigate("/");
    }
  }, []);

  const handleProceed = () => {
    // Example redirection based on the current pathname
    if (adminInfo.role === "Admin") {
      console.log(adminInfo.role);
      navigate("/admin/dashboard");
    } else if (adminInfo.role === "Urban Planner") {
      navigate("/admin");
    } else {
      switch (location.pathname) {
        case "/":
          navigate("/user");
          break;
        case "/admin-home":
          navigate(ADMIN_LOGIN);
          break;
        default:
          navigate("/default-page");
      }
    }
  };

  return (
    <>
      <div className={styles.welcomeContainer}>
        <img src={neomLogo2} alt="NEOM Logo" className={styles.logo} draggable="false" />
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.backgroundVideo}
          preload="auto"
        >
          <source src={neomWelcomePlay} type="video/mp4" />
        </video>
        <div className={styles.overlayText}>
          <h1>{text?.welcome_to[lang]}</h1>
          <h1>{text?.virtual_humans[lang]}</h1>
          <div>
            <button className={styles.proceedButton} onClick={handleProceed}>
              <div className={styles.btnProceed}>
                {text?.proceed[lang]}
                <span className={styles.greaterThan}>
                  <img src={greaterIcon} draggable="false" alt="gerater than logo" className={styles.greaterIcon} />
                </span>
              </div>
            </button>
          </div>
          <div
            className={styles.adminLink}
            onClick={() => {
              navigate(ADMIN_LOGIN);
            }}
          >
            <span className={styles.adminLinkText}>{text?.sign_in_as_admin[lang]}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
