import { createBrowserRouter } from "react-router-dom";
import WelcomeScreen from "../components/participantScreen/WelcomeScreen/WelcomeScreens";
import ParticipantLoginScreen from "../components/participantScreen/ParticipantsScreens/ParticipantLoginScreen";
import ParticipantLayout from "../components/participantScreen/ParticipantsScreens/ParticipantLayout/ParticipantLayout";
import ParticipantProfileCreation from "../components/participantScreen/ParticipantsScreens/ParticipantsProfileCreationScreen/ParticipantProfileCreation";
import ParticipantProfileViewScreen from "../components/participantScreen/ParticipantsScreens/ParticipantProfileView/ParticipantProfileViewScreen";
import ParticipantLoginLayout from "../components/participantScreen/ParticipantsScreens/ParticipantLayout/ParticipantloginLayout";
import {
  HOME,
  PARTICIPANT,
  PARTICIPANT_PROFILE_VIEW,
} from "../components/apiRoutes/participantRoutes/participantsRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import {
  ADMIN_DASHBOARD,
  ADMIN_HOMEPAGE,
  ADMIN_LOGIN,
  ADMIN_SIGNIN_MESSAGE,
  ADMIN_SIMULATIONS,
} from "../components/apiRoutes/adminRoutes/adminRoutes";
import AdminLogin from "../components/adminScreen/AdminLogin/AdminLogin";
import AdminLayout from "../components/adminScreen/AdminLayout.jsx/AdminLayout";
import AdminDashboard from "../components/adminScreen/AdminDadhboard/AdminDashboard";
import AdminSigninMessage from "../components/adminScreen/AdminSigninMessage/AdminSigninMessage";
import AdminProtectedRoutes from "./AdminProtectedRoutes";
import { RDP_DASHBOARD } from "../components/apiRoutes/rdpParticipantRoutes/rdpParticipantRoutes";
import RDPDashboard from "../components/rdpScreen/RDPDashboard/RDPDashboard";
import AdminSimulations from "../components/adminScreen/AdminSimulations/AdminSimulations";

export const router = createBrowserRouter([
  {
    path: HOME,
    element: <WelcomeScreen />,
  },
  {
    path: PARTICIPANT,
    element: <ParticipantLoginLayout />,
    children: [
      {
        path: "",
        element: <ParticipantLoginScreen />,
      },
    ],
  },
  {
    path: "/participant-profile",
    element: (
      <ProtectedRoutes>
        <ParticipantLayout />
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "",
        element: <ParticipantProfileViewScreen />,
      },
      {
        path: "profile-submission",
        element: <ParticipantProfileCreation />,
      },
      // Additional protected routes as needed
    ],
  },

  {
    path: PARTICIPANT_PROFILE_VIEW,
    element: (
      <ProtectedRoutes>
        <ParticipantProfileViewScreen />
      </ProtectedRoutes>
    ),
  },
  {
    path: RDP_DASHBOARD,
    element: (
      <ProtectedRoutes>
        <RDPDashboard />
      </ProtectedRoutes>
    ),
  },

  // ADMIN FLOW STARTED
  {
    path: ADMIN_HOMEPAGE,
    element: <WelcomeScreen />,
  },
  {
    path: `${ADMIN_LOGIN}`,
    element: <AdminLayout />,
    children: [
      {
        path: "",
        element: <AdminLogin />,
      },
      {
        path: `${ADMIN_SIGNIN_MESSAGE}`,
        element: (
          <AdminProtectedRoutes>
            <AdminSigninMessage />
          </AdminProtectedRoutes>
        ),
      },
    ],
  },
  {
    path: ADMIN_SIMULATIONS,
    element: (
      <AdminProtectedRoutes>
        <AdminSimulations />
      </AdminProtectedRoutes>
    ),
  },
  {
    path: ADMIN_DASHBOARD,
    element: (
      <AdminProtectedRoutes>
        <AdminDashboard />
      </AdminProtectedRoutes>
    ),
    children: [
      // {
      //   path:"",
      // }
    ],
  },
]);
