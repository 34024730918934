import React from "react";

const LightActiveIcon = ({ styles }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={styles}
  >
    <path
      d="M15.2 7.3592H14.416C13.9736 7.3592 13.616 7.6464 13.616 8C13.616 8.3528 13.9736 8.6392 14.416 8.6392H15.2C15.6416 8.6392 16 8.3536 16 8C16 7.6472 15.6408 7.3592 15.2 7.3592ZM8 3.6C7.42168 3.5982 6.84871 3.71079 6.31407 3.93127C5.77943 4.15176 5.29366 4.47579 4.88473 4.88473C4.47579 5.29366 4.15176 5.77943 3.93127 6.31407C3.71079 6.84871 3.5982 7.42168 3.6 8C3.6 10.4408 5.5592 12.4 8 12.4C10.44 12.4 12.4 10.4408 12.4 8C12.4 5.5592 10.4392 3.6 8 3.6ZM8 11.2C6.2312 11.2 4.8 9.7672 4.8 8C4.8 6.2312 6.2312 4.8 8 4.8C8.84869 4.8 9.66263 5.13714 10.2627 5.73726C10.8629 6.33737 11.2 7.15131 11.2 8C11.2 8.84869 10.8629 9.66263 10.2627 10.2627C9.66263 10.8629 8.84869 11.2 8 11.2ZM2.4 8C2.4 7.6472 2.0408 7.3592 1.6 7.3592H0.8C0.3576 7.3592 0 7.6464 0 8C0 8.3528 0.3576 8.6392 0.8 8.6392H1.6C2.0408 8.6392 2.4 8.3528 2.4 8ZM8 2.4C8.3528 2.4 8.6392 2.0424 8.6392 1.6V0.8C8.6392 0.3576 8.3528 0 8 0C7.6464 0 7.3592 0.3576 7.3592 0.8V1.6C7.3592 2.0424 7.6464 2.4 8 2.4ZM8 13.6C7.6464 13.6 7.3592 13.9576 7.3592 14.4V15.2C7.3592 15.6424 7.6464 16 8 16C8.3528 16 8.6392 15.6424 8.6392 15.2V14.4C8.6392 13.9576 8.3528 13.6 8 13.6ZM13.892 3.0128C14.2048 2.7 14.2552 2.244 14.0056 1.9944C13.756 1.7448 13.2992 1.796 12.988 2.1088L12.428 2.668C12.1152 2.9808 12.0648 3.4368 12.3144 3.6864C12.564 3.936 13.0208 3.8848 13.3328 3.572L13.892 3.0128ZM2.6672 12.4264L2.1072 12.9872C1.7944 13.3 1.744 13.7544 1.9936 14.004C2.2432 14.2536 2.7 14.204 3.0112 13.8912L3.5712 13.332C3.884 13.0192 3.9344 12.5632 3.6848 12.3128C3.4352 12.0624 2.9784 12.1152 2.6672 12.4264ZM3.012 2.108C2.7 1.7952 2.2432 1.744 1.9936 1.9936C1.744 2.2432 1.7952 2.7 2.1064 3.0128L2.6664 3.572C2.9792 3.8848 3.4344 3.936 3.684 3.6864C3.9336 3.4368 3.8832 2.98 3.5712 2.668L3.012 2.108ZM12.4272 13.3328L12.9872 13.892C13.3 14.2048 13.7552 14.2544 14.0048 14.0064C14.2544 13.7568 14.204 13.3 13.8912 12.988L13.332 12.4288C13.0192 12.116 12.5632 12.0648 12.3128 12.3144C12.0624 12.564 12.1144 13.02 12.4272 13.3328Z"
      fill="#686868"
    />
  </svg>
);

export default LightActiveIcon;
