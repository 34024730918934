import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useInactivityDetector } from "../components/session-timeout/InactivityDetector";
import SessionTimeoutModal from "../utils/Modals/SessionTimeOutModal/SessionTimeoutModal";
import constants from "../constants";
import {
  ADMIN_DASHBOARD,
  ADMIN_SIMULATIONS,
} from "../components/apiRoutes/adminRoutes/adminRoutes";
import { RDP_DASHBOARD } from "../components/apiRoutes/rdpParticipantRoutes/rdpParticipantRoutes";
import { PARTICIPANT_PROFILE_VIEW } from "../components/apiRoutes/participantRoutes/participantsRoutes";

const ProtectedRoutes = ({ children }) => {
  const { loggedIn, signOut, role, adminInfo } = useAuth();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const timeout = 1000 * 60 * 25; //25 minute

  useEffect(() => {
    const checkPreviousSession = () => {
      const closeTime = localStorage.getItem("closeTime");
      if (closeTime) {
        const currentTime = Date.now();
        const timePassed = (currentTime - parseInt(closeTime)) / 1000;
        console.log("Time passed since last close:", timePassed);
        if (timePassed > 1800 && loggedIn) {
          // Consider additional validation if needed
          setIsModalOpen(true);
        } else {
          localStorage.removeItem("closeTime"); // Clear if not applicable
        }
      }
    };

    const handleBeforeUnload = () => {
      if (loggedIn) {
        localStorage.setItem("closeTime", Date.now());
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    checkPreviousSession();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      localStorage.removeItem("closeTime");
    };
  }, [loggedIn]);

  useInactivityDetector(timeout, () => {
    if (loggedIn) {
      setIsModalOpen(true);
      localStorage.setItem("isModalOpen", "true"); // Store the state in localStorage
    }
  });

  useEffect(() => {
    // Check localStorage for session timeout state
    if (localStorage.getItem("isModalOpen") === "true") {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    // let logoutTimer;
    if (isModalOpen) {
      setIsModalOpen(true)
    }
  }, [isModalOpen]);

  const handleLogout = () => {
    signOut();
    setIsModalOpen(true);
    localStorage.removeItem("isModalOpen"); // Clear the state from localStorage
    navigate("/user"); // Navigate to a neutral or initial route
  };

  // Route based on user role
  useEffect(() => {
    if (!loggedIn) {
      navigate("/user");
    } else if (role === constants.RDP) {
      navigate(RDP_DASHBOARD);
    } else if (role === constants.PARTICIPANT) {
      navigate(PARTICIPANT_PROFILE_VIEW);
    } else if (adminInfo.role === "Admin") {
      navigate(ADMIN_DASHBOARD);
    } else if (adminInfo.role === constants.URBAN_PLANNER) {
      navigate(ADMIN_SIMULATIONS);
    }
  }, [role, adminInfo, loggedIn]);

  if (!loggedIn) {
    return <Navigate to="/user" replace />;
  }

  return (
    <>
      {children}
      <SessionTimeoutModal isOpen={isModalOpen} onConfirm={handleLogout} />
    </>
  );
};

export default ProtectedRoutes;
