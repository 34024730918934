import React, { useEffect, useState } from "react";
import styles from "./ParticipantSetting.module.css";
import { useAuth } from "../../../../context/AuthContext";
import axios from "axios";
import { documentLink, participantProfileView } from "../../../apiRoutes/participantApi/participant-api";
import ThreeDotsLoader from "../../../../sharedComponents/ThreeDotsLoader";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import constants from "../../../../constants";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const ParticipantSetting = () => {
  const { authData, decodedData, signOut } = useAuth();
  const [loading, setLoading] = useState(false);

  const [participantProfileData, setParticipantProfileData] = useState({
    age: "",
    gender: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when starting the fetch

      const idToken =
        authData?.data?.tokenresponse?.IdToken ||
        localStorage.getItem("id_token");
      const accessToken =
        authData?.data?.tokenresponse?.AccessToken ||
        localStorage.getItem("access_token");

      if (!accessToken || !idToken) {
        console.error("Tokens are not available");
        setLoading(false); // Remember to set loading to false if exiting early
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: idToken,
        },
      };

      try {
        const response = await axios.get(participantProfileView, config);
        if (response.data) {
          setParticipantProfileData(response.data.data);
        }
      } catch (error) {
        if (
          error.response?.data.message.includes(
            constants.ACCESS_TOKEN_IS_INVALID
          )
        ) {
          signOut();
        } else {
          console.error("Error fetching participants data:", error);
        }
      } finally {
        setLoading(false); // Set loading to false after the request is completed
      }
    };

    fetchData();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div className={styles.settingsContainer}>
      <h2 className={styles.settingsTitle}>{text?.settings[lang]}</h2>
      <div className={styles.section}>
        <div className={styles.inputGroup}>
          <label className={styles.label}>{text?.first_name[lang]}</label>
          <div className={styles.inputRow}>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={capitalizeFirstLetter(decodedData?.name)}
              className={styles.inputfirstName}
              readOnly
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          {!loading ? (
            <div className={styles.inputRow}>
              <div className={styles.input20}>
                <label className={styles.label}>{text?.age[lang]}</label>
                <input
                  type="text"
                  value={participantProfileData.age || ""}
                  className={styles.inputLabel}
                  readOnly
                />
              </div>
              <div className={styles.input80}>
                <label className={styles.label}>{text?.gender[lang]}</label>
                <input
                  type="text"
                  value={capitalizeFirstLetter(
                    text?.genders[participantProfileData.gender]?.[lang] || ""
                  )}
                  className={styles.inputLabel}
                  readOnly
                />
              </div>
            </div>
          ) : (
            <ThreeDotsLoader color="#000" />
          )}
          <div className={styles.settingFooter}>
            <InfoIcon
              className={styles.infoImg}
              sx={{ fontSize: "18px", color: "#686868" }}
            />
            <span className={styles.infoText}>
              {text?.to_ensure_privacy_mobile_number_not_displayed[lang]}
            </span>
          </div>
        </div>
        <div className={styles.helpLink}>
          <a href={documentLink} target="_blank" className={styles.needHelpLink}>
            {text?.user_guide[lang]}
          </a>
          <ExpandMoreRoundedIcon
            sx={{
              fontSize: "25px",
              color: "#A2A2A2",
              transform: "rotate(-90deg)",
            }}
            className={styles.greaterThanArrow}
            fontSize="medium"
          />
        </div>
      </div>
      <div className={styles.homeInfo}>
        <p className={styles.paragraph}>
          <span className={styles.asterisk}>*</span>
          <span className={styles.text}>
            {text?.all_info_accessed_through_home[lang]}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ParticipantSetting;
