import React from "react";
import styles from "./Banner.module.css";

const Banner = ({ onRefresh, message, action }) => {
  return (
    <div>
      <div className={styles.overlay}></div>
      <div className={styles.networkErrorBanner}>
        <span className={styles.networkErrorText}>
          {message}
          <span className={styles.networkRefreshText} onClick={onRefresh}>
            {action}
          </span>
        </span>
      </div>
    </div>
  );
};

export default Banner;
