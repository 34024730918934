import React, { useState, useRef, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import styles from "./OTPInputComponent.module.css";
import { useAuth } from "../../../../context/AuthContext";
import {
  loginParticipant,
  signInParticipant,
} from "../../../apiRoutes/participantApi/participant-api";
import { toast } from "react-toastify";
import { login } from "../../../../utils/AuthFile/Auth";
import "../../../../font.css";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { Checkbox } from "@mui/material";
import constants from "../../../../constants";
import { text } from "../../../../constant/text.js";
import { lang } from "../../../../constant/Var.js";

const RDPOTPsInputComponents = ({
  showEmailOTP = true,
  showMobileOTP = true,
  otpsVerified,
  mobileNumber,
}) => {
  const [mobileOTP, setMobileOTP] = useState(new Array(6).fill(""));
  const [emailOTP, setEmailOTP] = useState(new Array(6).fill(""));
  const [errors, setErrors] = useState({ phone: false, email: false, network: false });
  const [isLoading, setIsLoading] = useState(false);
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const phoneOTPRef = useRef([]);
  const emailOTPRef = useRef([]);
  const [isDataPrivacyChecked, setIsDataPrivacyChecked] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const { setAuth, loginInfo } = useAuth();
  const [autoFocusOTP, setAutoFocusOTP] = useState(false);
  const isOtpComplete =
    mobileOTP.every((digit) => digit.trim() !== "") &&
    emailOTP.every((digit) => digit.trim() !== "");
  const [isAccountDisabled, setIsAccountDisabled] = useState(false);

  useEffect(() => {
    if (autoFocusOTP) {
      emailOTPRef.current[0].focus();
      setAutoFocusOTP(false);
    }
  }, [autoFocusOTP]);

  const startTimer = () => {
    setShowRegenerate(false);
    setIsInputDisabled(false);

    clearInterval(window.timer);

    window.timer = setInterval(() => {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const startingTime = localStorage.getItem("timer");

      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(window.timer);
          setShowRegenerate(true);
          setIsInputDisabled(true);
          return 0;
        }
        return startingTime - currentTime;
      });
    }, 1000);
  };

  useEffect(() => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    localStorage.setItem("timer", currentTime + 59);
    startTimer();
    return () => clearInterval(window.timer);
  }, []);

  const handleChange = (element, index, type) => {
    if (isNaN(element.value)) return false;
    if (!/^\d$/.test(element.value)) {
      return false;
    }
    setErrors({ email: false, phone: false });
    const newOtp = type === "email" ? [...emailOTP] : [...mobileOTP];
    newOtp[index] = element.value;

    if (type === "email") setEmailOTP(newOtp);
    else setMobileOTP(newOtp);

    setErrors({ ...errors, [type]: false });
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyUp = (event, index, ref, type) => {
    if (event.key === "Backspace") {
      const newOtp = type === "email" ? [...emailOTP] : [...mobileOTP];
      const inputTypeValue =
        type === "email" ? emailOTP[index] : mobileOTP[index];

      setErrors({ email: false, phone: false });

      if (inputTypeValue === "") {
        if (index > 0) {
          newOtp[index - 1] = "";
          ref.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
      }
      if (type === "email") setEmailOTP(newOtp);
      else setMobileOTP(newOtp);
    }
  };

  const handleLogin = async (e) => {
    let errors = {phone: false, email: false,network: false}
    setErrors(errors);
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    // Clear the countdown timer
    clearInterval(window.timer);
    
    toast.dismiss("sign-in-toast");
    try {
      const response = await axios.post(signInParticipant, {
        phone_number: mobileNumber,
        otp: mobileOTP?.join(""),
        email_otp: emailOTP?.join(""),
        session: localStorage.getItem("otp_session"),
      });
      let errors = {network: false}
      setIsLoading(false)
      if (response?.data) {
        if (response?.data?.success) {
          setErrors(errors);
          otpsVerified();
        }
        loginInfo();
        login();
        setAuth(response.data);
        localStorage.removeItem("timer");
      }
    } catch (error) {
      const errorData = error?.response?.data;
      if (error.message === "Network Error") {
        setShowRegenerate(true);
        let isNetworkError = error.message?.includes("Network Error")
        let errors = {network: isNetworkError}
        setErrors(errors);
      } else if (error.response.data.message.toLowerCase().includes(constants.FAILED_TO_GENERATE_TOKEN)){
        let errors = {network: false}
        setErrors(errors);
        setIsAccountDisabled(true)
        setShowRegenerate(false);
        setIsInputDisabled(true);
      } else if (errorData) {
        let isEmailError = errorData?.message?.includes("email");
        let isPhoneError = errorData?.message?.includes("phone");
        let errors = { email: isEmailError, phone: isPhoneError };
        setErrors(errors);
        setShowRegenerate(true);
        setIsInputDisabled(true);
      }  
      setIsLoading(false)

    } finally {
      setIsLoading(false);
    }
  };

  const handleRegenerateOtp = async () => {
    setIsLoading(true);
    let errors = {network: false}
    setErrors(errors);
    try {
      const response = await axios.post(loginParticipant, {
        phone_number: mobileNumber,
      });
      localStorage.setItem(
        "otp_session",
        response.data.data.login_response.data.Session
      );
      const currentTime = Math.floor(new Date().getTime() / 1000);
      localStorage.setItem("timer", currentTime + 59);
      setTimeLeft(59);
      startTimer();
      setIsLoading(false)
      setMobileOTP(new Array(6).fill(""));
      setEmailOTP(new Array(6).fill(""));
      setErrors({ email: false, phone: false });
      setIsDataPrivacyChecked(false);
      if (response?.data) {
        setAutoFocusOTP(true);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        setShowRegenerate(true);
        let isNetworkError = error.message?.includes("Network Error")
        let errors = {network: isNetworkError}
        setErrors(errors);
      } else if (error.response.data.message.toLowerCase().includes(constants.FAILED_TO_GENERATE_TOKEN) || error.response.data.message.includes("Access is denied for this User !")){
        localStorage.removeItem("timer");
        let errors = {network: false}
        setErrors(errors);
        setIsAccountDisabled(true)
        setShowRegenerate(false);
        setIsInputDisabled(true);
      } else{
        setShowRegenerate(false);
        setIsInputDisabled(true);
      }    
      setIsLoading(false)
    }
  };

  return (
    <>
      <p className={styles.subHeadingOtp}>
        {text?.please_enter_otp_mobile_email[lang]}
      </p>
      {showEmailOTP ? (
        <>
          <div className={styles.otpContainer}>
            <div className={styles.otpTitle}>
              <span className={styles.otpLabels}>{text?.email_otp[lang]}</span>
            </div>
            {/* OTP input fields */}
            <div className={styles.otpInputs}>
              {emailOTP.map((digit, index) => (
                <input
                  key={index}
                  type="password"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e.target, index, "email")}
                  onKeyUp={(e) => handleKeyUp(e, index, emailOTPRef, "email")}
                  onFocus={(e) => e.target.select()}
                  ref={(el) => (emailOTPRef.current[index] = el)}
                  disabled={isInputDisabled || isLoading}
                  className={`${styles.otpInput} ${
                    errors?.email
                      ? styles.rdpInvalidOtpInput
                      : !errors?.email
                      ? styles.validOtpInput
                      : ""
                  }`}
                  autoFocus={index == 0}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {showMobileOTP ? (
        <>
          <div className={styles.otpContainer}>
            <div className={styles.otpTitle}>
              <span className={styles.otpLabels}>{text?.mobile_number_otp[lang]}</span>
            </div>
            {/* OTP input fields */}
            <div className={styles.otpInputs}>
              {mobileOTP.map((digit, index) => (
                <input
                  key={index}
                  type="password"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e.target, index, "phone")}
                  onKeyUp={(e) => handleKeyUp(e, index, phoneOTPRef, "phone")}
                  onFocus={(e) => e.target.select()}
                  ref={(el) => (phoneOTPRef.current[index] = el)}
                  disabled={isInputDisabled || isLoading}
                  className={`${styles.otpInput} ${
                    errors?.phone
                      ? styles.rdpInvalidOtpInput
                      : !errors?.phone
                      ? styles.validOtpInput
                      : ""
                  }`}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {(errors?.phone || errors?.email) && <div className={styles.error}>{text?.validations.invalid_otp[lang]}</div>}
      {errors?.network && <div className={styles.error}>{text?.validations.something_is_wrong[lang]}</div>}

      {showRegenerate ? null : (
        <span className={styles.resendOTPMsg}>
          {text?.resend_otp[lang]}{" "}
          <span style={{ color: "#EBC03F" }}>
            in{" "}
            {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? "0" : ""}${
              timeLeft % 60
            }`}{" "}
            {Math.floor(timeLeft / 60) > 0 ? "min" : "min"}
          </span>
        </span>
      )}
      {isAccountDisabled && <div className={styles.error}>{text?.validations.number_is_disabled[lang]}</div>}

      <div className={styles.consentContainer}>
        <Checkbox
          id="privacyCheckbox"
          checked={isDataPrivacyChecked}
          onChange={(e) => setIsDataPrivacyChecked(e.target.checked)}
          className={styles.privacyPolicycheckBox}
          disabled={showRegenerate || isLoading || isAccountDisabled}
          icon={<CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}
          checkedIcon={<CheckBoxOutlinedIcon fontSize="small" />}
          sx={{
            color: "#A2A2A2",
            '&.Mui-checked': {
              color: "#016F4A",
            },
            "&.Mui-disabled": {
              color: "#A2A2A2",
            },
          }}
        />
        <label
          htmlFor="privacyCheckbox"
          className={`${styles.consentText} ${
            isDataPrivacyChecked ? styles.checkedConsentText : ""
          } ${showRegenerate || isLoading || isAccountDisabled? styles.disabledConsentText : ""}`}
        >
        {text?.rdp_consent_text[lang]}
        </label>
      </div>
      <div className={styles.btnGrp}>
        {!showRegenerate && (
          <button
            className={`${styles.verifyButton} ${
              isOtpComplete && isDataPrivacyChecked && !isLoading && !isAccountDisabled
                ? styles.verifyButtonActive
                : styles.verifyButtonInactive
            }`}
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "flex-end",
            }}
            onClick={handleLogin}
            disabled={
              !isOtpComplete ||
              !isDataPrivacyChecked ||
              isInputDisabled ||
              isAccountDisabled ||
              isLoading
            }
          >
            {isLoading ? (
              <ThreeDots
                height="20" // Adjust size as needed
                width="30" // Adjust size as needed
                color="#fff" // Set to gray as per your requirement
                ariaLabel="loading"
              />
            ) : (
              text?.sign_in[lang]
            )}
          </button>
        )}
        {showRegenerate && (
          <button
            className={styles.regenerateButton}
            onClick={handleRegenerateOtp}
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            {isLoading ? (
              <ThreeDots
                height="20" // Adjust size as needed
                width="30" // Adjust size as needed
                color="#fff" // Set to gray as per your requirement
                ariaLabel="loading"
              />
            ) : (
              text?.regenerate_otp[lang]
            )}
          </button>
        )}
      </div>
    </>
  );
};

export default RDPOTPsInputComponents;
