import React from 'react'
import styles from './AdminDashboard.module.css'
import AdminNav from '../AdminReusableComponent/AdminNav/AdminNav'
import AdminUserTable from '../AdminIUserTable/AdminUserTable'

const AdminDashboard = () => {
  return (
    <div className={styles.dashboardContainer}>
        <div>
            <AdminNav/>
            <AdminUserTable/>
        </div>
    </div>
  )
}

export default AdminDashboard
