import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { graphs, module45Data, tiranData } from "./constants";
import GraphsContainer from "./GraphsContainer";
import styles from "./VisualizationTable.module.css";

const Visualization = ({
  simulation = null,
  onSimulationChange = () => {},
  category = "Evolution",
  module = "Module45",
}) => {
  const rows = module === "Module45" ? module45Data : tiranData;
  console.log("CATEGORY", category);
  const graphList =
    category === "Evolution" ? graphs?.evolution : graphs?.engagement;

  const handleViewClick = (simulationName) => {
    onSimulationChange(simulationName);
  };

  return (
    <div>
      {simulation ? (
        <GraphsContainer
          category={category}
          module={module}
          graphs={graphList}
        />
      ) : (
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table aria-label="simulation table" className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={styles.boldText}>
                  Simulation Name
                </TableCell>
                <TableCell align="left" className={styles.boldText}>
                  Execution Start Date
                </TableCell>
                <TableCell align="left" className={styles.boldText}>
                  Status
                </TableCell>
                <TableCell align="left" className={styles.boldText}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left" style={{ color: "#5E5E5E" }}>
                    {row.name}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#5E5E5E" }}>
                    {row.date}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: row.status === "InProgress" ? "green" : "#5E5E5E",
                    }}
                  >
                    {row.status}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#5E5E5E" }}>
                    <Link
                      underline="always"
                      onClick={() => handleViewClick(row.name)}
                      className={styles.link}
                    >
                      View
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Visualization;
