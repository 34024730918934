import React from "react";

const CrossIcon = ({ className }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="16" cy="16" r="16" fill="white" />
    <path
      d="M12.6402 11.27L16.5002 15.13L20.3402 11.29C20.425 11.1997 20.5272 11.1275 20.6406 11.0777C20.754 11.0278 20.8763 11.0014 21.0002 11C21.2654 11 21.5197 11.1054 21.7073 11.2929C21.8948 11.4804 22.0002 11.7348 22.0002 12C22.0025 12.1226 21.9797 12.2444 21.9333 12.3579C21.8869 12.4714 21.8178 12.5742 21.7302 12.66L17.8402 16.5L21.7302 20.39C21.895 20.5512 21.9916 20.7696 22.0002 21C22.0002 21.2652 21.8948 21.5196 21.7073 21.7071C21.5197 21.8946 21.2654 22 21.0002 22C20.8727 22.0053 20.7456 21.984 20.6268 21.9375C20.508 21.8911 20.4002 21.8204 20.3102 21.73L16.5002 17.87L12.6502 21.72C12.5657 21.8073 12.4647 21.8769 12.3532 21.925C12.2416 21.9731 12.1216 21.9986 12.0002 22C11.7349 22 11.4806 21.8946 11.2931 21.7071C11.1055 21.5196 11.0002 21.2652 11.0002 21C10.9978 20.8774 11.0206 20.7556 11.067 20.6421C11.1134 20.5286 11.1826 20.4258 11.2702 20.34L15.1602 16.5L11.2702 12.61C11.1053 12.4488 11.0087 12.2304 11.0002 12C11.0002 11.7348 11.1055 11.4804 11.2931 11.2929C11.4806 11.1054 11.7349 11 12.0002 11C12.2402 11.003 12.4702 11.1 12.6402 11.27Z"
      fill="#5E5E5E"
    />
  </svg>
);

export default CrossIcon;
