import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { participantProfileView } from "../../../apiRoutes/participantApi/participant-api";
import axios from "axios";
import styles from "./ParticipantDiagnostic.module.css";
import VRIconYellow from "../../../../assets/svgicons/VRIconYellow";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";

const ParticipantDiagnostic = () => {
  const { authData, setDiagnosticsEnabled } = useAuth();
  const [diagnosticPassword, setDiagnosticPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken =
        authData?.data?.tokenresponse?.AccessToken ||
        localStorage.getItem("access_token");
      const idToken =
        authData?.data?.tokenresponse?.IdToken ||
        localStorage.getItem("id_token");

      if (!accessToken || !idToken) {
        console.error("Tokens are not available");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: idToken,
        },
      };

      try {
        setLoading(true);
        const response = await axios.get(participantProfileView, config);
        setDiagnosticsEnabled(true);
        setDiagnosticPassword(response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authData?.data?.tokenresponse]);

  return (
    <div className={styles.diagnosticContainer}>
      <div className={styles.diagnosticHeader}>{text?.diagnostics[lang]}</div>
      <div className={styles.diagnosticImgText}>
        <div className={styles.diagWrapper}>
          <div className={styles.diagnosticVrImg}>
            <VRIconYellow className={styles.vrImg} />
          </div>
          <div className={styles.diagnosticImgtxt}>
            <span className={styles.diagnosticImgtxtConetnt}>
              {text?.plesae_continue_on_vr[lang]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantDiagnostic;
