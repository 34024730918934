import React, { useState, useEffect } from "react";
import CustomSlider from "./../CustomSlider/CustomSlider"; 
import styles from "./SliderWithInput.module.css";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const SliderWithInput = ({ selected3DWorld, settotalNPCs  }) => {
  const [value, setValue] = useState(null);
  const getMaxLimit = () => {
    if (selected3DWorld === "module45") return 10000;
    if (selected3DWorld === "tiran") return 10000;
    return 0; // Default max value for the slider
  };
  const [maxLimit, setMaxLimit] = useState(getMaxLimit());

  useEffect(() => {
    const newMaxLimit = getMaxLimit();
    if (value !== null && value > newMaxLimit) {
      setValue(newMaxLimit);
    }
    setMaxLimit(newMaxLimit);
  }, [selected3DWorld, value]);
  
  // const handleSliderChange = (event, newValue) => {
  //   if (newValue <= maxLimit) {
  //     setValue(newValue);
  //   }
  // };

  const handleSliderChange = (event, newValue) => {
    const adjustedValue = Math.round(newValue / 500) * 500; // Snap to nearest 500
    if (adjustedValue <= maxLimit) {
      setValue(adjustedValue);
    }
  };

  useEffect(() => {
    settotalNPCs(value); // Update totalNpcs whenever value changes
  }, [value, settotalNPCs]);

  // const handleInputChange = (event) => {
  //   const inputValue = event.target.value;
  //   // Check if input is not empty and is a valid number
  //   if (inputValue === '') {
  //     setValue(null);
  //   } else {
  //     const numericValue = Number(inputValue);
  //     if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= maxLimit) {
  //       setValue(numericValue);
  //     }
  //   }
  // };

  // const handleIncrease = () => {
  //   if (value === null) {
  //     setValue(1); // Set to 1 if the value is null
  //   } else if (value < maxLimit) {
  //     setValue(value + 1);
  //   }
  // };

  // const handleDecrease = () => {
  //   if (value === null) {
  //     return; // Do nothing if value is null
  //   } else if (value > 1) {
  //     setValue(value - 1);
  //   } else {
  //     setValue(null); // Reset to null if decrementing below 1
  //   }
  // };

  const handleIncrease = () => {
    if (value === null) {
      setValue(500); // Set to the first step if value is null
    } else if (value + 500 <= maxLimit) {
      setValue(value + 500);
    }
  };

  const handleDecrease = () => {
    if (value === null || value <= 500) {
      setValue(null); // Reset to null if decrementing below 500
    } else {
      setValue(value - 500);
    }
  };

  return (
    <div className={styles.sliderContainer}>
      <CustomSlider
        value={value || 0} // Ensure slider receives a number
        onChange={handleSliderChange}
        max={maxLimit}
      />
      <div className={styles.inputContainer}>
        <input
          type="number"
          value={value === null ? '' : value}
          // onChange={handleInputChange}
          className={styles.inputBox}
          placeholder="0"
          disabled
        />
        <div className={styles.buttonGroup}>
          <KeyboardArrowUpRoundedIcon 
            onClick={handleIncrease} 
            className={styles.arrowIcon}
          />
          <KeyboardArrowDownRoundedIcon 
            onClick={handleDecrease} 
            className={styles.arrowIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default SliderWithInput;
