let constants = {
    "USER_SUCCESS": "User created successfully",
    "PARTICIPANT_SUCCESS": "Participant added successfully",
    "USER_ACCOUNT_ALREADY_EXISTS": "User account already exists",
    "MOBILE_NUMBER_ALREADY_EXISTS": "Mobile number already exists",
    "RDP": "Raw Data Provider",
    "URBAN_PLANNER": "Urban Planner",
    "PARTICIPANT": "participant",
    "USER_ACCOUNT_ALREADY_EXISTS_AND_EXPIRED": "User account already exists and is expired. Please call with action RESEND to reset user account",
    "ACCESS_TOKEN_IS_INVALID": "Access token is not valid",
    "FAILED_TO_GENERATE_TOKEN": "failed to genrate token",
    "EMAIL_ALREADY_EXISTS": "Email already exist, please try again"

};

export default constants;