import React, { useState, useEffect } from "react";
import Refresh from "../../../../assets/icons/qrefresh.svg";
import DropdownSelect from "../../../../sharedComponents/DropdownSelect/DropDdownSelect";
import styles from "./SimulationDetails.module.css";
import { allField } from "./constants";

const IndividualGraph = ({ module = "Module45", data = {}, options = [] }) => {
  const [selected, setSelected] = useState(allField);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(true);

  const url = `${data?.url}#p.modelname=${module}${
    data.filtered ? `&p.location=${selected?.value}` : ""
  }`;

  useEffect(() => {
    setLoading(true);
    setKey((prev) => prev + 1);
  }, [module, url]);

  const handleRefresh = () => {
    setLoading(true);
    setKey((prev) => prev + 1);
  };

  return (
    <div className={styles.iframeContainer}>
      {data?.filtered && !loading && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            justifyContent: "flex-end",
            zIndex: 2,
            right: "5px",
            top: "10px",
          }}
        >
          <DropdownSelect
            options={[allField, ...options]}
            value={selected.value}
            onChange={(val) => {
              setSelected(val);
              handleRefresh();
            }}
            placeholder="Select an Option"
            style={{
              width: "235px",
              height: "25px",
            }}
          />
        </div>
      )}
      <div className={styles.iframeBox}>
        <iframe
          key={key}
          width="100%"
          height="100%"
          src={url}
          onLoad={() => setLoading(false)}
        ></iframe>
      </div>
      <img
        onClick={handleRefresh}
        alt="Refresh"
        src={Refresh}
        style={{
          zIndex: 2,
          position: "absolute",
          cursor: "pointer",
          bottom: "10px",
          left: "10px",
          height: "24px",
          width: "24px",
        }}
      />
    </div>
  );
};

export default IndividualGraph;
