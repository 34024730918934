import React, { useContext, useState, useEffect } from "react";
import { SimulationContext } from "../../../../context/SimulationCotext";
import DropdownSelect from "../../../../sharedComponents/DropdownSelect/DropDdownSelect";
import styles from "./SimulationRecording.module.css";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../../../sharedComponents/CustomInput/CustomInput";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  simulationModule45,
  simulationRecording,
  simulationTiran,
} from "../../../apiRoutes/simulationApi/simulation-api";
import axios from "axios";
import { useAuth } from "../../../../context/AuthContext";
import { toast } from "react-toastify";
import { format } from "date-fns";
import CircularYellowTick from "../../../../assets/svgicons/CircularYellowTick";
import DatePickerMulti from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

const SimulationRecording = ({ dropdown3DWorld, setNetworkError }) => {
  const { simulationState } =
    useContext(SimulationContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [startDate, setStartDate] = useState(
    simulationState.simulationRecording.startDate
  );
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [startTime, setStartTime] = useState(
    simulationState.simulationRecording.startTime
  );
  const [time, setTime] = useState(null);
  const { authData } = useAuth();
  const accessToken = authData?.data?.tokenresponse?.AccessToken;
  const [selected3DWorld, setSelected3DWorld] = useState("");
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

  const handleAreaChange = (selectedOption) => {
    if (selectedOption.value) {
      setSelectedArea(selectedOption.value); // Store the selected area object
    }
  };

  const handle3DWorldChange = (selectedOption) => {
    if (selectedOption) {
      setSelected3DWorld(selectedOption.value);
      // Fetch areas based on the selected 3D World
      const url =
        selectedOption.value === "module45"
          ? simulationModule45
          : simulationTiran;
      axios
        .get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
        .then((response) => {
          const areasFromAPI = response.data.data.areas.map((area) => ({
            value: area,
            label: area,
          }));
          setSelectedAreas(areasFromAPI);
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            console.log("network error", error);
            setNetworkError(true);
          } else {
            console.error("Error fetching data:", error);
          }
        });
    } else {
      setSelectedAreas([]);
    }
  };

  const handleStart = () => {
    const url = simulationRecording;
    console.log(simulationState, "pppp");
    const formattedStartDateTime =
      format(startDate, "yyyy-MM-dd") + "T" + time;

    const requestBody = {
      modeltype: selected3DWorld,
      startdatetime: formattedStartDateTime,
      area: selectedArea,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    console.log(requestBody);
    axios
      .post(url, requestBody, config)
      .then(() => {
        toast.success("Recording started successfully.");
        setIsSubmitted(true);
      })
      .catch((error) => {
        if (error.message.includes("Network Error")) {
          console.log("network error", error);
          setNetworkError(true);
        } else {
          toast.error("Failed to start Recording");
          console.error("Error starting simulation:", error);
        }
      });
  };

  useEffect(() => {
    // Enable the Next button only if all required fields are filled
    const isFormValid =
      selected3DWorld && selectedArea && startDate && startTime;
    setIsNextButtonEnabled(isFormValid);
  }, [selected3DWorld, selectedArea, startDate, startTime]);


  const handleChange = (value) => {
    // Extract hour and minute from the value
    const hour = value.hour;
    const minute = value.minute;
    
    // Convert to 12-hour format with AM/PM
    let ampm = 'AM';
    let hour12 = hour;
  
    if (hour >= 12) {
      ampm = 'PM';
      if (hour > 12) {
        hour12 = hour - 12;
      }
    } else if (hour === 0) {
      hour12 = 12; // Midnight case
    }
  
    // Format time as "hh:mm A"
    const formattedTime = `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
  
    console.log("Converted Time:", formattedTime);
    
    // Update the state with the formatted time
    setStartTime(value); 
    setTime(formattedTime);
  };

  return (
    <div className={styles.maincontainer}>
      <div className={styles.mainSection}>
        <h1 className={styles.simulationHeading}>
          {text.simulations?.configure_simulation_recording[lang]}
        </h1>
        <div className={styles.blocks}>
          <label className={styles.labels}>
            {text.simulations?.threed_world[lang]}
          </label>
          <DropdownSelect
            style={{ marginTop: 10, marginRight: -5 }}
            options={dropdown3DWorld}
            value={selected3DWorld}
            onChange={handle3DWorldChange}
            label="Select 3D World"
            color={isSubmitted ? "#F9F9F9" : "#fff"}
            border={isSubmitted ? "1px solid #E5E5E5" : "1px solid #E5E5E5"}
            indicatorstyle={"15"}
            isSubmitted={isSubmitted}
          />
        </div>
        <div className={styles.blocks}>
          <label className={styles.labels}>
            {text.simulations?.area[lang]}
          </label>
          <DropdownSelect
            style={{ marginTop: 10, marginRight: -5 }}
            options={selectedAreas}
            value={selectedArea}
            onChange={handleAreaChange}
            label="Select Area"
            color={isSubmitted ? "#F9F9F9" : "#fff"}
            border={isSubmitted ? "1px solid #E5E5E5" : "1px solid #E5E5E5"}
            indicatorstyle={"15"}
            isSubmitted={isSubmitted}
          />
        </div>
        <div className={styles.dateandtime}>
          <label className={styles.labels} style={{ marginBottom: 10 }}>
            {text.simulations?.select_date[lang]}
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            dateFormat="dd/MM/YYYY"
            placeholderText="Select"
            customInput={
              <CustomInput isSubmitted={isSubmitted} isLarge={true} />
            }
          />
        </div>
        <div
          className={styles.dateandtime}
          style={{
            marginTop: 7,
            position: "relative",
            // display: "inline-block",
          }}
        >
          <label className={styles.labels} style={{ marginBottom: 10 }}>
            {text.simulations?.select_time[lang]}
          </label>
          <DatePickerMulti
            disableDayPicker
            format="hh:mm A"
            plugins={[
              <TimePicker hideSeconds position="bottom"/>
            ]} 
            placeholder="Select"
            inputClass={`${styles.customdatepicker} ${
              isSubmitted ? styles.disabledInput : ""
            }`}
            value={startTime}
            onChange={handleChange} 
            disabled={isSubmitted}  
          />
          <style jsx>{`
            .rmdp-arrow-container:hover {
                background-color: #EBC03F; /* Directly target and apply color */
            }
            .rmdp-arrow {
              border: solid #EBC03F;;
              border-width: 0 2px 2px 0;
            }
            .rmdp-time-picker div input {
              color: #1D1F22;
              font-size: 14px;
              font-family: "Brown", sans-serif;
              font-weight: 400;
            }
            .rmdp-time-picker div {
              color: #1D1F22;
              font-size: 14px;
              font-family: "Brown", sans-serif;
              font-weight: 400;
            }
          `}</style>
          <label htmlFor="timePicker" className={styles.icon}>
            <AccessTimeIcon
              className={`${styles.customTimeIcon} ${
                isSubmitted ? styles.disabledInput : ""
              }`}
            />
          </label>
        </div>
        {isSubmitted && (
          <div className={styles.messageContainer}>
            <CircularYellowTick />
            <span className={styles.messageText}>
              {text?.simulations?.your_video_will_be_available_soon[lang]}
            </span>
          </div>
        )}
        <div
          className={`${styles.footerBtnContainer} ${
            isSubmitted ? styles.submittedFooter : ""
          }`}
        >
          <button
            type="button"
            onClick={handleStart}
            className={`${styles.nextBtn} ${
              isNextButtonEnabled && !isSubmitted ? styles.nextBtnEnable : ""
            }`}
            disabled={!isNextButtonEnabled || isSubmitted}
          >
            {isSubmitted
              ? text?.simulations?.go_to_visualisation_dashboard[lang]
              : text?.simulations?.submit[lang]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SimulationRecording;
