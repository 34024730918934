import React, { useState } from "react";
import styles from "./ProfileNavigation.module.css";
import LogoutModal from "../../../../ModalComonent/LogoutModal";
import HomeIcon from "../../../../../assets/svgicons/HomeIcon";
import ActiveHomeIcon from "../../../../../assets/svgicons/ActiveHomeIcon";
import SettingIcon from "../../../../../assets/svgicons/SettingIcon";
import ActiveSettingIcons from "../../../../../assets/svgicons/ActiveSettingIcons";
import LogoutIcon from "../../../../../assets/svgicons/LogoutIcon";
import { text } from "../../../../../constant/text";
import { lang } from "../../../../../constant/Var";

const ProfileNavigation = ({
  activeIcon,
  setActiveIcon,
  handleLogoutClick,
  toggleMenu = () => {},
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const confirmLogout = () => {
    hideModal();
    handleLogoutClick();
  };

  return (
    <div className={styles.statusSection}>
      <div>
        <div
          className={styles.iconWithLabel}
          onClick={() => {
            toggleMenu();
            setActiveIcon("home");
          }}
        >
          {activeIcon === "home" ? (
            <ActiveHomeIcon
              style={{ fill: "#686868", width: "16px", height: "16px" }}
              className={styles.icon}
            />
          ) : (
            <HomeIcon
              style={{ fill: "#686868", width: "16px", height: "16px" }}
              className={styles.icon}
            />
          )}
          <span
            className={
              activeIcon === "home" ? styles.activeIconLabel : styles.iconLabel
            }
          >
            {text?.home[lang]}
          </span>
        </div>

        <div
          className={styles.iconWithLabel}
          onClick={() => {
            toggleMenu();
            setActiveIcon("settings");
          }}
        >
          {activeIcon === "settings" ? (
            <ActiveSettingIcons
              style={{ fill: "#686868", width: "16px", height: "16px" }}
              className={styles.icon}
            />
          ) : (
            <SettingIcon
              style={{ fill: "#686868", width: "16px", height: "16px" }}
              className={styles.icon}
            />
          )}
          <span
            className={
              activeIcon === "settings"
                ? styles.activeIconLabel
                : styles.iconLabel
            }
          >
            {text?.settings[lang]}
          </span>
        </div>
      </div>
      <div
        className={`${styles.iconWithLabel} ${styles.logOutIcon}`}
        onClick={() => {
          toggleMenu();
          showModal();
        }}
      >
        <LogoutIcon
          style={{ fill: "#686868", width: "16px", height: "16px" }}
          styles={styles}
        />
        <span className={styles.logOutIconLabel}>{text?.logout[lang]}</span>
      </div>
      <LogoutModal
        isOpen={isModalOpen}
        onCancel={hideModal}
        onConfirm={confirmLogout}
      />
    </div>
  );
};

export default ProfileNavigation;
