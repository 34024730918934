import React from "react";

const SearchIcon = ({ styles }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.icon}
  >
    <path
      d="M15.2825 14.2176L12.5 11.4576C13.5801 10.1109 14.1032 8.40152 13.9617 6.68103C13.8201 4.96053 13.0248 3.35964 11.7392 2.20753C10.4536 1.05543 8.7754 0.439683 7.04974 0.486903C5.32408 0.534124 3.68209 1.24072 2.46141 2.46141C1.24072 3.68209 0.534124 5.32408 0.486903 7.04974C0.439683 8.77541 1.05543 10.4536 2.20753 11.7392C3.35964 13.0248 4.96053 13.8201 6.68102 13.9617C8.40152 14.1032 10.1109 13.5801 11.4576 12.5001L14.2176 15.2601C14.2873 15.3303 14.3702 15.3861 14.4616 15.4242C14.553 15.4623 14.651 15.4819 14.75 15.4819C14.8491 15.4819 14.9471 15.4623 15.0385 15.4242C15.1299 15.3861 15.2128 15.3303 15.2825 15.2601C15.4177 15.1202 15.4933 14.9333 15.4933 14.7388C15.4933 14.5443 15.4177 14.3574 15.2825 14.2176ZM7.25005 12.5001C6.2117 12.5001 5.19666 12.1921 4.33331 11.6153C3.46995 11.0384 2.79704 10.2185 2.39968 9.25914C2.00232 8.29983 1.89836 7.24423 2.10093 6.22583C2.3035 5.20743 2.80351 4.27197 3.53774 3.53774C4.27197 2.80351 5.20743 2.3035 6.22583 2.10093C7.24423 1.89836 8.29983 2.00232 9.25914 2.39968C10.2185 2.79704 11.0384 3.46995 11.6153 4.33331C12.1921 5.19666 12.5 6.2117 12.5 7.25005C12.5 8.64244 11.9469 9.97779 10.9624 10.9624C9.97779 11.9469 8.64244 12.5001 7.25005 12.5001Z"
      fill="#686868"
    />
  </svg>
);

export default SearchIcon;
