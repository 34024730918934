import React, { useContext, useEffect, useState } from "react";
import styles from "./SimulationDuration.module.css";
import { SimulationContext } from "../../../../context/SimulationCotext";
import CheckboxParameter from "../../../../sharedComponents/Checkbox/CheckboxParameter";
import TemperatureLightIcon from "../../../../assets/svgicons/TemperatureLightIcon";
import LightActiveIcon from "../../../../assets/svgicons/LightActiveIcon";
import SoundIcon from "../../../../assets/svgicons/SoundIcon";
import WindDisabledIcon from "../../../../assets/svgicons/WindDisabledIcon";
import LightInactiveIcon from "../../../../assets/svgicons/LightInactiveIcon";
import SoundInactiveIcon from "../../../../assets/svgicons/SoundInactiveIcon";
import TemperatureActiveIcon from "../../../../assets/svgicons/TempratureActiveIcon";
import WindActiveIcon from "../../../../assets/svgicons/WindActiveIcon";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";
import SliderWithInput from "../../../../sharedComponents/SliderWithInput/SliderWithInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; //required css for calendar pop-up
import CustomInput from "../../../../sharedComponents/CustomInput/CustomInput";

const SimulationDuration = ({
  selectedParameters,
  handleCheckBox,
  selected3DWorld,
}) => {
  const { simulationState, updateSimulationState } =
    useContext(SimulationContext);
  const isDimmed = !selected3DWorld?.length;

  const [startDate, setStartDate] = useState(
    simulationState.simulationDuration.startDate
  ); 
  const [endDate, setEndDate] = useState(
    simulationState.simulationDuration.endDate
  );
  const handleUpdateState = (field, value) => {
    updateSimulationState({
      ...simulationState,
      simulationDuration: {
        ...simulationState.simulationDuration,
        [field]: value,
      },
    });
  };
 const [totalNPSs, settotalNPCs] = useState(
    simulationState.totalNPCs
  );
  useEffect(() => {
    updateSimulationState({ totalNPCs: totalNPSs });
  }, [totalNPSs]);

  const maxEndDate = startDate ? new Date(startDate.getTime() + 365 * 24 * 60 * 60 * 1000) : null;

  return (
    <div className={styles.container}>
      <div className={styles.blocks}>
        <h1 className={styles.simulationHeading}>
          {text?.simulations?.virtual_world[lang]}
        </h1>
        <label className={styles.labels}>
          {text?.simulations?.number_of_npcs[lang]}
        </label>
        <SliderWithInput selected3DWorld={selected3DWorld} settotalNPCs={settotalNPCs}/>
      </div>

      <label className={styles.labels}>{text?.simulations?.simulation_months[lang]}</label>

      <div className={styles.inputGroup}>
        <div className={styles.dateTime}>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              handleUpdateState("startDate", date);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
            customInput={<CustomInput/>}
            wrapperClassName={styles.fullWidth}
            maxDate={endDate}
          />
        </div>
        <div className={styles.dateTime} style={{marginLeft: "-4%"}}>
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              handleUpdateState("endDate", date);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
            customInput={<CustomInput />}
            minDate={startDate}
            maxDate={maxEndDate}
            wrapperClassName={styles.fullWidth}
          />
        </div>
      </div>

      <div className={styles.blocks}>
        <label className={styles.labels}>{text?.simulations?.parameters[lang]}</label>
        <div className={styles.checkboxParametersWrapper}>
          <table className={styles.checkboxTable}>
            <tbody>
              <tr>
                <td>
                  {selectedParameters.includes("Light") ? (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Light")}
                        onChange={() => handleCheckBox("Light")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                        color={{ borderColor: isDimmed ? "#E5E5E5" : "" }}
                      />
                      <label htmlFor="light" className={styles.checkboxLabel}>
                        <LightActiveIcon
                          styles={{
                            marginRight: "9px",
                            marginLeft: "4px",
                          }}
                        />
                        {text?.simulations?.light[lang]}
                      </label>
                    </div>
                  ) : (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Light")}
                        onChange={() => handleCheckBox("Light")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                      />
                      <label htmlFor="light" className={styles.inactiveLabel}>
                        <LightInactiveIcon
                          styles={{
                            marginRight: "9px",
                            marginLeft: "4px",
                          }}
                        />
                        {text?.simulations?.light[lang]}
                      </label>
                    </div>
                  )}
                </td>
                <td>
                  {selectedParameters.includes("Sound") ? (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Sound")}
                        onChange={() => handleCheckBox("Sound")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                        color={{ borderColor: isDimmed ? "#E5E5E5" : "" }}
                      />
                      <label htmlFor="sound" className={styles.checkboxLabel}>
                        <SoundIcon
                          styles={{
                            marginRight: "9px",
                            marginLeft: "4px",
                          }}
                        />
                        {text?.simulations?.sound[lang]}
                      </label>
                    </div>
                  ) : (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Sound")}
                        onChange={() => handleCheckBox("Sound")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                      />
                      <label htmlFor="sound" className={styles.inactiveLabel}>
                        <SoundInactiveIcon
                          styles={{
                            marginRight: "9px",
                            marginLeft: "4px",
                          }}
                        />
                        {text?.simulations?.sound[lang]}
                      </label>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {selectedParameters.includes("Temperature") ? (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Temperature")}
                        onChange={() => handleCheckBox("Temperature")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                        color={{ borderColor: isDimmed ? "#E5E5E5" : "" }}
                      />
                      <TemperatureActiveIcon
                        styles={{
                          marginRight: "9px",
                          marginLeft: "9px",
                        }}
                      />
                      <label
                        htmlFor="temperature"
                        className={styles.checkboxLabel}
                      >
                        {text?.simulations?.temperature[lang]}
                      </label>
                    </div>
                  ) : (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Temperature")}
                        onChange={() => handleCheckBox("Temperature")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                      />
                      <TemperatureLightIcon
                        styles={{
                          marginRight: "9px",
                          marginLeft: "9px",
                        }}
                      />
                      <label
                        htmlFor="temperature"
                        className={styles.inactiveLabel}
                      >
                        {text?.simulations?.temperature[lang]}
                      </label>
                    </div>
                  )}
                </td>
                <td>
                  {selectedParameters.includes("Wind") ? (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Wind")}
                        onChange={() => handleCheckBox("Wind")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                        color={{ borderColor: isDimmed ? "#E5E5E5" : "" }}
                      />
                      <label htmlFor="wind" className={styles.checkboxLabel}>
                        <WindActiveIcon
                          styles={{
                            marginRight: "9px",
                            marginLeft: "4px",
                          }}
                        />
                        {text?.simulations?.wind[lang]}
                      </label>
                    </div>
                  ) : (
                    <div className={styles.checkboxWrapper}>
                      <CheckboxParameter
                        checked={selectedParameters.includes("Wind")}
                        onChange={() => handleCheckBox("Wind")}
                        disabled={true}
                        style={{ cursor: "not-allowed" }}
                        borderColor="#E5E5E5"
                      />
                      <label htmlFor="wind" className={styles.inactiveLabel}>
                        <WindDisabledIcon
                          styles={{
                            marginRight: "9px",
                            marginLeft: "4px",
                          }}
                        />
                        {text?.simulations?.wind[lang]}
                      </label>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SimulationDuration;
