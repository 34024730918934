import React, { useState } from "react";
import { categoryOptions } from "../VisualizationTable/constants";
import DropdownSelect from "./../../../../sharedComponents/DropdownSelect/DropDdownSelect";
import styles from "./NavbarVisualizationDashboard.module.css";
import DownloadIcon from "../../../../assets/icons/material-symbols_download.svg";
import Button from '@mui/material/Button';

const NavbarVisualizationDashboard = ({
  onOptionChange,
  heading,
  type = "Module45",
  category = "Evolution",
  onCategoryChange = () => {},
  onSimulationChange = () => {},
  backButton,
}) => {
  const options = [
    { value: "Module45", label: "Module 45" },
    { value: "Tiran", label: "Tiran" },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleDropdownChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onOptionChange(selectedOption.value);
  };

  const handleCategoryDropdownChange = (selectedOption) => {
    onCategoryChange(selectedOption);
  };

  const handleBackClick = () => {
    onSimulationChange(null);
  };

  const dropdownStyle = {
    width: "100%",
    maxWidth: "400px", 
    backgroundColor: "#FFFFFF!important",
    height: "45px", 
    marginTop: "-3px",
   
  };
  
  return (
    <div className={styles.navbarContainer}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          color: "#5e5e5e",
          cursor: "pointer",
          fontWeight: 600,
        }}
        onClick={handleBackClick}
      >
        {backButton}
        <h1 className={styles.navbarHeading}>{heading}</h1>
      </div>
      <div className={styles.formContainer}>
        {type === "Module45" || type === "Tiran" ? (
          <>
            <DropdownSelect
              options={options}
              value={selectedOption.value}
              onChange={handleDropdownChange}
              color={"#fff"}
              placeholder="Select a Module"
              style={dropdownStyle} 
            />
            {/* <Button
              variant="contained"
              className={styles.vido}
              onClick={() => console.log("Button clicked!")}
              disabled
            >
              <img src={DownloadIcon} alt="Download icon" />
               <span className={styles.videoText}>Video</span>
            </Button> */}
            
            {/* Please remove formContainer width completely in case of adding this above button again */}
          </>
        ) : (
          <>
            <DropdownSelect
              options={categoryOptions}
              value={category?.value}
              color={"#fff"}
              onChange={handleCategoryDropdownChange}
              placeholder="Select a Category"
               
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NavbarVisualizationDashboard; 