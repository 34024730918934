import React from "react";

const FilterIcon = ({ styles }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.icon}
  >
    <path
      d="M3.375 5.25H14.625M5.25 9H12.75M7.5 12.75H10.5"
      stroke="#686868"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterIcon;
