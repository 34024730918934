const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const loginUser = BASE_URL + "auth-user/login";
export const signInUser = BASE_URL + "auth-user/login/response";
export const logoutUser = BASE_URL + "auth-user/logout";
export const listUser = BASE_URL + "user/list-users";
export const createUser = BASE_URL + "user/create";
export const inactiveUser = BASE_URL + "user/inactive";





