import React from "react";

const LogoutIcon = ({ styles }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.icon}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.75 8.00024C10.75 7.86763 10.6974 7.74046 10.6036 7.64669C10.5098 7.55292 10.3827 7.50024 10.25 7.50024H2.93471L4.24205 6.38024C4.29195 6.33752 4.33295 6.28538 4.3627 6.22682C4.39246 6.16825 4.41038 6.10439 4.41546 6.0389C4.42054 5.9734 4.41266 5.90755 4.39229 5.84509C4.37191 5.78264 4.33944 5.72481 4.29671 5.67491C4.25399 5.62501 4.20186 5.58401 4.14329 5.55425C4.08472 5.5245 4.02086 5.50657 3.95537 5.5015C3.88987 5.49642 3.82402 5.50429 3.76156 5.52467C3.69911 5.54504 3.64128 5.57752 3.59138 5.62024L1.25805 7.62024C1.20316 7.66718 1.15909 7.72546 1.12887 7.79105C1.09865 7.85665 1.08301 7.92802 1.08301 8.00024C1.08301 8.07247 1.09865 8.14383 1.12887 8.20943C1.15909 8.27503 1.20316 8.3333 1.25805 8.38024L3.59138 10.3802C3.69216 10.4665 3.82309 10.5092 3.95537 10.499C4.08764 10.4887 4.21043 10.4264 4.29671 10.3256C4.383 10.2248 4.42571 10.0939 4.41546 9.96159C4.40521 9.82931 4.34283 9.70653 4.24205 9.62024L2.93538 8.50024H10.25C10.3827 8.50024 10.5098 8.44756 10.6036 8.35379C10.6974 8.26003 10.75 8.13285 10.75 8.00024Z"
      fill="#5E5E5E"
    />
    <path
      d="M6.25 5.33301C6.25 5.80101 6.25 6.03501 6.36267 6.20367C6.41119 6.27619 6.47348 6.33848 6.546 6.38701C6.71467 6.49967 6.94867 6.49967 7.41667 6.49967H10.25C10.6478 6.49967 11.0294 6.65771 11.3107 6.93901C11.592 7.22032 11.75 7.60185 11.75 7.99967C11.75 8.3975 11.592 8.77903 11.3107 9.06033C11.0294 9.34164 10.6478 9.49967 10.25 9.49967H7.41667C6.94867 9.49967 6.71467 9.49967 6.546 9.61167C6.47342 9.66039 6.41112 9.72291 6.36267 9.79567C6.25 9.96434 6.25 10.1983 6.25 10.6663C6.25 12.5517 6.25 13.495 6.836 14.0803C7.42133 14.6663 8.364 14.6663 10.2493 14.6663H10.916C12.8027 14.6663 13.7447 14.6663 14.3307 14.0803C14.9167 13.495 14.9167 12.5517 14.9167 10.6663V5.33301C14.9167 3.44767 14.9167 2.50434 14.3307 1.91901C13.7447 1.33301 12.802 1.33301 10.9167 1.33301H10.25C8.364 1.33301 7.42133 1.33301 6.836 1.91901C6.25 2.50434 6.25 3.44767 6.25 5.33301Z"
      fill="#5E5E5E"
    />
  </svg>
);

export default LogoutIcon;
