import React from "react";
import styles from "./SimulationNavigation.module.css"; 
import ConfigureIcon from "../../../../assets/svgicons/ConfigureIcon";
import ConfigureRecording from "../../../../assets/svgicons/ConfigureRecording";
import VisualizationIcon from "../../../../assets/svgicons/VisualizationIcon";
import { text } from "../../../../constant/text";
import { lang } from "../../../../constant/Var";
import ConfigureIconInactive from "../../../../assets/svgicons/ConfigureIconInactive";
import ConfigureRecordingActive from "../../../../assets/svgicons/ConfigureRecordingActive";
import VisualizationIconActive from "../../../../assets/svgicons/VisualizationIconActive";

const SimulationNavigation = ({ activeOption, setActiveOption }) => {
  return (
    <div className={styles.statusSection}>
      <div
        className={`${styles.iconWithLabel} ${
          activeOption === "option1" ? styles.activeOption : styles.inactiveOption
        }`}
        onClick={() => setActiveOption("option1")}
      >
        {activeOption === "option1" ? <ConfigureIcon /> : <ConfigureIconInactive />}
        <span
          className={
            activeOption === "option1"
              ? styles.activeIconLabel
              : styles.iconLabel
          }
        >
          {text?.simulations?.configure_simulation[lang]}
        </span>
      </div>
      {/* <div
        className={`${styles.iconWithLabel} ${
          activeOption === "option2" ? styles.activeOption : styles.inactiveOption
        }`}
        onClick={() => setActiveOption("option2")}
      >
        {activeOption === "option2" ? <ConfigureRecordingActive /> : <ConfigureRecording />}
        <span
          className={
            activeOption === "option2"
              ? styles.activeIconLabel
              : styles.iconLabel
          }
        >
          {text?.simulations?.configure_simulation_recording[lang]}
        </span>
      </div> */}
      <div
        className={`${styles.iconWithLabel} ${
          activeOption === "option3" ? styles.activeOption : styles.inactiveOption
        }`}
        onClick={() => setActiveOption("option3")}
      >
        {activeOption === "option3" ? <VisualizationIconActive /> : <VisualizationIcon />}
        <span
          className={
            activeOption === "option3"
              ? styles.activeIconLabel
              : styles.iconLabel
          }
        >
          {text?.simulations?.visualization_dashboard[lang]}
        </span>
      </div>
    </div>
  );
};

export default SimulationNavigation;
