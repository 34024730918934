import React, { useEffect, useState } from "react";
import styles from "./CreateParticipantModal.module.css";
import PrimaryPhoneNumber from "../../../../sharedComponents/PrimaryPhoneNumber";
import constants from "../../../../constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ThreeDots } from "react-loader-spinner";
import { capitalizeFirstLetter } from "../../../UtilityFunction/capitalizeFirstLetter";
import "../../../../font.css";
import { text } from "../../../../constant/text.js";
import { lang } from "../../../../constant/Var.js";

const CreateParticipantModal = ({
  isOpen,
  onCancel,
  onConfirm,
  responseMessage,
  isResponseSuccess,
  setResponseMessage,
}) => {
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [fullPhoneNumber, setFullPhoneNumber] = useState("");
  const [fullPhoneNumberError, setFullPhoneNumberError] = useState(""); // Add state to keep track of role selection error
  const [isVerifying, setIsVerifying] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationError, setVerificationError] = useState(""); // State to hold verification error messages
  const isMobileNumberAlreadyExists =
  responseMessage === constants.MOBILE_NUMBER_ALREADY_EXISTS ||
  responseMessage === "User account already exists and is expired. Please call with action RESEND to reset user account";
  
  useEffect(() => {
    if (responseMessage === constants.PARTICIPANT_SUCCESS) {
      setFullPhoneNumber(`+${selectedPhoneCode} ${phoneNumber}`);
    }
  }, [responseMessage, selectedPhoneCode, phoneNumber]);

  if (!isOpen) return null;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let isValidForm = true;
    setIsVerifying(false);
    if (responseMessage === constants.PARTICIPANT_SUCCESS) {
      setUsername("");
      setUsernameError("");
      setFullPhoneNumber("");
      setFullPhoneNumberError("");
      setResponseMessage("");
      setSelectedPhoneCode("");
      setPhoneNumber("");
      setVerificationError("");
      return;
    }

    // Validate username
    if (!username || username.trim().length === 0) {
      setUsernameError(text?.user_creation_errors?.username_is_required[lang]);
      isValidForm = false;
      return;
    } else if (username.trim().length === 1) {
      setUsernameError(text?.user_creation_errors?.username_character_length[lang]);
      isValidForm = false;
      return;
    } else {
      setUsernameError("");
    }

    if (!fullPhoneNumber) {
      setFullPhoneNumberError(text?.user_creation_errors?.please_enter_valid_number[lang]);
      isValidForm = false;
    } else {
      setFullPhoneNumberError("");
      isValidForm = true;
    }

    if (verificationError) isValidForm = false;
    else {
      setVerificationError("");
      isValidForm = true;
    }

    if (isValidForm) {
      try {
        setIsVerifying(true); // Start the loader
        await onConfirm(username, fullPhoneNumber);
        setIsVerifying(false);
      } catch (error) {
        console.error("API call failed:", error);
        setIsVerifying(false);
      }
    }
  };

  const handleCancel = () => {
    setUsername("");
    setUsernameError("");
    setFullPhoneNumber("");
    setFullPhoneNumberError("");
    setResponseMessage("");
    setSelectedPhoneCode("");
    setPhoneNumber("");
    setVerificationError("");
    onCancel();
  };

  const handleUserName = (value) => {
    const regex = /^[a-zA-Z]+$/;
    if (value && !regex.test(value)) return;
    if (value && value.length > 15) return;
    setUsername(value);

    if (!value || value.trim().length === 0) {
      setUsernameError(text?.user_creation_errors?.firstname_is_required[lang]);
    } else if (value.trim().length === 1) {
      // If one character or less, set the error message
      setUsernameError(text?.user_creation_errors?.firstname_character_length[lang]);
    } else {
      // If more than one character, clear the error message
      setUsernameError("");
    }
    setUsername(capitalizeFirstLetter(value));
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{text?.add_participant[lang]}</h2>
        <form onSubmit={handleFormSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="username" className={styles.label}>
            {text?.first_name[lang]}
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => handleUserName(e.target.value)}
              className={
                `${styles.modalInput} 
                 ${usernameError  ? styles.inputError : ""} 
                 ${
                 responseMessage === constants.USER_SUCCESS
                   ? styles.disabledField
                   : ""
               }`}
              required
              placeholder={text?.enter_only_first_name[lang]}
              disabled={responseMessage === constants.PARTICIPANT_SUCCESS||isVerifying}
            />
            {usernameError && (
              <div className={styles.usernameError}>{usernameError}</div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="mobilenumber" className={styles.label}>
            {text?.mobile_number[lang]}
            </label>

            <PrimaryPhoneNumber
              id={"mobilenumber"}
              setFullPhoneNumber={setFullPhoneNumber}
              isDisabled={responseMessage === constants.PARTICIPANT_SUCCESS||isVerifying}
              selectedPhoneCode={selectedPhoneCode}
              setSelectedPhoneCode={setSelectedPhoneCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              verificationError={verificationError}
              setVerificationError={setVerificationError}
              setResponseMessage={setResponseMessage}
              responseError={
                responseMessage !== constants.PARTICIPANT_SUCCESS
                  ? responseMessage
                  : null
              }
              responseMessage={responseMessage}
            />
            {fullPhoneNumberError && (
              <div className={styles.roleError}>{fullPhoneNumberError}</div>
            )}
          </div>

          <div>
            {responseMessage === constants.PARTICIPANT_SUCCESS ? (
              <div className={styles.responseTextBox}>
                <div className={styles.iconTextWrapper}>
                  {isResponseSuccess ? (
                    <CheckCircleIcon sx={{ color: "#016f4a", fontSize: 22 }} />
                  ) : null}
                  <span className={isResponseSuccess ? styles.successText : ""}>
                    {responseMessage}
                  </span>
                </div>
              </div>
            ) : null}
            <div className={styles.modalActions}>
              <button
                type="button"
                onClick={handleCancel}
                className={styles.modalCancel}
                disabled={isVerifying}
              >
                {text?.close[lang]}
              </button>
              <button
                type="submit"
                className={styles.modalConfirm}
                disabled={
                  !username ||
                  !fullPhoneNumber ||
                  isVerifying ||
                  usernameError ||
                  fullPhoneNumberError ||
                  verificationError ||
                  !selectedPhoneCode ||
                  !phoneNumber ||
                  isMobileNumberAlreadyExists
                }
              >
                {isVerifying ? (
                  <ThreeDots
                    height="20" // Adjust size as needed
                    width="30" // Adjust size as needed
                    color="#fff" // Set to gray as per your requirement
                    ariaLabel="loading"
                  />
                ) : responseMessage === constants.PARTICIPANT_SUCCESS ? (
                  text?.add_another[lang]
                ) : (
                  text?.add[lang]
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateParticipantModal;
