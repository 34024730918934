import React, { useState, useEffect } from "react";
import {
  m45PersonalSkillsOptions,
  tiranPersonalSkillsOptions,
} from "./constants";
import IndividualGraph from "./IndividualGraph";
import styles from "./SimulationDetails.module.css";

const GraphsContainer = ({ module = "Module45", graphs = [] }) => {
  const options =
    module === "Module45"
      ? m45PersonalSkillsOptions
      : tiranPersonalSkillsOptions;

  const filteredGraphs =
    graphs?.filter((x) => x?.modules?.includes(module)) || [];

  const [currentPage, setCurrentPage] = useState(0);
  const graphsPerPage = 4;
  const totalGraphs = filteredGraphs.length;
  const totalPages = Math.ceil(totalGraphs / graphsPerPage);

  const currentGraphs = filteredGraphs.slice(
    currentPage * graphsPerPage,
    (currentPage + 1) * graphsPerPage
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [graphs, module]);

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className={styles.formContainer}>
      
      <div className={`${styles.gridContainer}`}>
        {currentGraphs.map((x, index) => (
          <IndividualGraph
            key={index}
            module={module}
            data={x}
            options={options}
          />
        ))}
      </div>
      {/* Pagination Controls */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {currentPage > 0 && (
          <button
            onClick={handlePrevious}
            style={{
              padding: "8px 16px",
              fontSize: "14px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "80px",
            }}
          >
            Previous
          </button>
        )}
        {currentPage < totalPages - 1 && (
          <button
            onClick={handleNext}
            style={{
              padding: "8px 16px",
              fontSize: "14px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "80px",
            }}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default GraphsContainer;
