import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./font.css";
import { useEffect } from "react";
import { logoutAllTabs } from "./utils/AuthFile/Auth";
import { useImageAssets } from "./context/ImageAssetsContext";
import 'react-toastify/dist/ReactToastify.css';
import './utils/CustomComponent/CustomToastStyles.css'

function App() {
  const imageAssetsData = useImageAssets()
  
  useEffect(()=>{
    console.log("hi")
  },[imageAssetsData])

  useEffect(() => {
    logoutAllTabs();
  }, []);


  useEffect(()=>{
    const checkAuth=()=>{
      const isReloadReq=localStorage.getItem("isReloadReq")
      if(isReloadReq){
        window.location.reload()
        localStorage.removeItem("isReloadReq")
      }
    }
    window.addEventListener("storage",checkAuth)
    return(()=>{
      window.removeEventListener("storage",checkAuth)
    })
  })

  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}

export default App;
